import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Administrations = () => {
  return (
    <div>
      <Helmet>
        <title>مجلس الإدارة</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title='مجلس الإدارة' title2='الدورة الثانية- أربع سنوات (2022-2025)'  />
        <main>
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>أ. إقبال بنت عبدالله بن صالح العمران</b> - رئيسة مجلس الإدارة <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> أ. 
فاطمة بنت سعد بن نصار النصار
</b> - نائبة رئيسة مجلس الإدارة <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>محمد بن صالح بن غرم الله الغامدي </b> - أمين الصندوق / المشرف المالي <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> مؤيد بن إبراهيم بن عبدالله الحدبان </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>د.
                    فوزان بن عبدالرحمن بن حمد الفوزان 
                    </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> عائشة بنت عبدالرحمن بن عبدالله العزاز </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> أ. خيرية بنت محمد بن عيسى الهاشم </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> نهاد بنت حمد بن علي العامر </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>د. عذاري عبدالرحمن مهناء اليابس </b> - عضو مجلس الإدارة <br />
                </p>
              </div>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Administrations
