const DataProg =  [ 
     {
      "category": "دار أندلسية",
      "name":"prog2",
      "id": 1,
      "list": [{
          "link": "../assets/downloadFiles/files/andlus2.pdf",
          "title": "تقرير دار أندلسية  ",
           
      }]
  },
  {
    "category": "مجلس أندلسية",
    "name":"prog1",
    "id": 3,
    "list": [{
        "link": "../assets/downloadFiles/files/andlusM.pdf",
        "title": "تقرير مجلس أندلسية  ",
         
    }]
},
  {
    "category": "إكسير",
    "name":"prog3",
    "id": 2,
    "list": [{
        "link": "../assets/downloadFiles/files/axerr.pdf",
        "title": "تقرير إكسير",
         
    }]
  }
  ]

export default DataProg
