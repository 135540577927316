import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Staff = () => {
  return (
    <div>
      <Helmet>
        <title>فريق العمل </title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="فريق العمل" />
        <main>
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>هند توفيق المبيض </b> - المدير التنفيذي <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>منيرة خالد الغنام </b> - مسؤولة إدارة التمكين القيمي والمهاري <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>أفنان فالح الدوسري </b> - مسؤولة إدارة التثقيف <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>فاطمة أحمد بودي </b> - مسؤولة إدارة العلاقات العامة والإعلام <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> خولة نبيل الحمدان </b> - المحاسبة <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> ساره عبدالمنعم الهارون </b> - مساعد إداري <br />
                </p>
              </div>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Staff
