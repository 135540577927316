
 export const files=[
    {"id":1, "title":"اللائحة الأساسية المعتمدة من الوزارة", "url":"/files/main.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":2, "title":"آلية إدارة المتطوعين", "url":"/files/q1.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":3, "title":"نظام الرقابة المالية", "url":"/files/q2.pdf", "created_at":"2020-12-15 21:05:28"},
    {"id":5, "title":"لائحة الصلاحيات", "url":"/files/q3.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":6, "title":"الميثاق الأخلاقي للعاملين في القطاع غير الربحي", "url":"/files/q5.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":7, "title":"اللائحة المالية", "url":"/files/q6.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":8, "title":"لائحة الموارد البشرية", "url":"/files/q8.pdf", "created_at":"2020-12-15 21:04:21"},
    {"id":9, "title":"لائحة مجلس الإدارة المعتمدة", "url":"/files/q9.pdf", "created_at":"2020-12-15 21:04:21"},

   
    {"id":10, "title":"لائحة حفظ الوثائق والمكاتبات والسجلات الخاصة", "url":"/files/q4.pdf", "created_at":"2020-12-15 21:04:21"},
];
