const Data2 = [
  {
    id: "1",
    title: " مجلس أندلسية ",
    category: "برامج التمكين",
    name:"prog1",
    img: "https://i.imgur.com/moFHkGd.jpg",
    link:"empPorgrams"
  },
  {
    id: "2",
    title: " صُبح ",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/47rsLVJ.jpg",
    link:"programs"
  },
  {
    id: "3",
    title: "رواس",
    category: "برامج نشر ثقافة القراءة",
    img: "https://i.imgur.com/r2BbwYX.jpg",
    link:"eduPorgrams"
  },
  {
    id: "4",
    title: "دار أندلسية",
    name:"prog2",
    category: "برامج التمكين",
    img: "https://i.imgur.com/eMoXKYo.jpg",
    link:"empPorgrams"
  },

  {
    id: "5",
    title: "رواح",
    category: "برامج نشر ثقافة القراءة",
    img: "https://i.imgur.com/joH3YGg.jpg",
    link:"eduPorgrams"
  },
  {
    id: "6",
    title: "بوصلة",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/LRJEomu.jpg",
    link:"programs"
  },
  {
    id: "7",
    title: "قبس",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/uosuvPa.jpg",
    link:"programs"
  },
  {
    id: "8",
    title: "قمارش",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/vXAHKC2.jpg",
    link:"programs"
  },
  {
    id: "9",
    title: "سقيفة",
    category: "برامج نشر ثقافة القراءة",
    img: "https://i.imgur.com/0MJOyiF.jpg",
    link:"eduPorgrams"
  },
 
  {
    id: "11",
    title: "أكسير",
    name:"prog3",
    category: "برامج التمكين",
    img: "https://i.imgur.com/4GiyCnQ.jpg",
    link:"empPorgrams"
  },
  {
    id: "12",
    title: "صيف قرطبة",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/KYTiM41.jpg",
    link:"programs"
  },
  // {
  //   id: "13",
  //   title: "رواس ١  ",
  //   category: "مغلق",
  //   img: "https://i.imgur.com/R5cHBBY.jpg",
  // },
  {
    id: "14",
    title: " إعتماد",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/aUSB4TS.jpg",
    link:"programs"
  },
  {
    id: "15",
    title: "سياح",
    category: "برامج نشر ثقافة القراءة",
    img: "https://i.imgur.com/CJtGjP7.jpg",
    link:"eduPorgrams"
    
  },
  {
    id: "16",
    title: "ليوان ",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/nsjWPfE.jpg",
    link:"programs"
  },
  {
    id: "17",
    title: "بانوراما ",
    category: "برامج المكتبة",
    img: "https://i.imgur.com/RSQRkBA.jpg",
    link:"programs"
  },
  
]

export default Data2
