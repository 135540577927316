import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Careers = () => {
  return (
    <div>
      <Helmet>
        <title> فرص وظيفية</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="فرص وظيفية" />
        <main>
          <div class="container">
            <section>
              <div class="row" style={{ textAlign: "right" }}>
                <div class="col-md-12">
                  <div class="section-heading">
                    <p className="small p-2">
                   نبحث عن أفراد مبدعين، يعملون وفق منظومة قيمية تسمو بهم وتتوج أعمالهم بالنجاح والتوفيق بإذن الله تعالى،، ليعود النفع عليهم وعلى بيئة العمل التي 
ينتمون إليها.
<br/>
لذا إذا كنت تمتلك الموهبة، المعرفة والخبرة لتحقيق 
رؤيتنا، اليوم وغداً ،.، يسرنا تكرمكم بتعبئة النموذج لتصاحبونا في ركب الإبداع والتميز .
                    </p>
                    <br />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLScpGKGhtBq5Y-JX4hnpBjQcxgtS4EDe4GZexyk1fG_vzUPj8Q/viewform"
                        class="blog-slider__button med-5"
                        style={{
                          width: "auto",
                          padding: "10px 30px",
                          textDecoration: "none",
                          color: "white",
                          cursor: "pointer",
                        }}
                        data-toggle="tooltip"
                        title="استمارة التوظيف في جمعية قرطبة"
                      >
                        إنضم إلينا
                      </a>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Careers
