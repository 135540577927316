import React from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

Modal.setAppElement('#root');

const VideoModal = ({ isOpen, onRequestClose, videoUrl }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose} // This will close the modal when clicking outside
      contentLabel="Video Modal"
      style={{
        content: {
          width: '80%', // Width of the modal
          maxWidth: '800px', // Maximum width of the modal
          height: '450px', // Fixed height of the modal
          margin: 'auto', // Center the modal horizontally and vertically
          padding: '0',
          borderRadius: '10px', // Rounded corners
          overflow: 'hidden', // Prevent overflow
          display: 'flex', // Flexbox to align content
          justifyContent: 'center',
          alignItems: 'center',
        },
        overlay: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.75)', // Dark overlay background
        },
      }}
      shouldCloseOnOverlayClick={true} // This ensures the modal closes when clicking outside of it
    >
      <ReactPlayer 
        url={videoUrl} 
        playing 
        controls 
        width="100%" 
        height="100%" // Make the video fill the modal
      />
      <button 
        onClick={onRequestClose} 
        style={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          padding: '10px 20px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        إغلاق
      </button>
    </Modal>
  );
};

export default VideoModal;
