import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const license  = () => {
  return (
    <div>
      <Helmet>
        <title> التراخيص</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="التراخيص" />
        <main>
        <div className="row justify-content-center">


          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
            
            <div className="col-md-4 mb-4">
            <div className="card " style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <a href={"../assets/downloadFiles/files/lis1.pdf" } target="_blank">
                      <div
                        className="card-body gallery-img"
                        style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                      ></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                        شهادة ترخيص الجمعية
                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
              </div>
              <div className="col-md-4 mb-4">

                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <a href={"../assets/downloadFiles/files/lis2.pdf" } target="_blank">
                      <div
                        className="card-body gallery-img"
                        style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                      ></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                        شهادة تسجيل الجمعية في مجلس الجمعيات الأهلية في المملكة 

                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                  </div>
                  <div className="col-md-4 mb-4">
                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <a href={"../assets/downloadFiles/files/lis3.pdf" } target="_blank">
                      <div
                        className="card-body gallery-img"
                        style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                      ></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                        ترخيص موقع جمع التبرعات الإلكتروني 

                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                  </div>
               
            </div>
          </div>
        
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default license 
