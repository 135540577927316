import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
const AnnualCer = () => {

  const images = [
    { id: 1, category: "الحفل الأول 2020", src: "https://i.imgur.com/QSOqFJg.jpeg" },
    { id: 2, category: "الحفل الأول 2020", src: "https://i.imgur.com/jMYqMN4.jpeg" },
    { id: 3, category: "الحفل الأول 2020", src: "https://i.imgur.com/152xgZM.jpeg" },
    { id: 4, category: "الحفل الأول 2020", src: "https://i.imgur.com/rET6s2I.png" },
    { id: 5, category: "الحفل الأول 2020", src: "https://i.imgur.com/Ekus2of.jpeg" },
    { id: 6, category: "الحفل الأول 2020", src: "https://i.imgur.com/FiC7EHt.jpeg" },
    { id: 7, category: "الحفل الأول 2020", src: "https://i.imgur.com/kq6lVxy.jpeg" },
    { id: 8, category:"الحفل الأول 2020", src: "https://i.imgur.com/Iywvfsi.jpeg" },
    { id: 9, category: "الحفل الأول 2020", src: "https://i.imgur.com/rVmxbgB.jpeg" },
   
    { id: 10, category: "الحفل الأول 2020", src: "https://i.imgur.com/eyCpSF8.jpeg" },
    { id: 11, category:"الحفل الأول 2020", src: "https://i.imgur.com/mAW3wS0.jpeg" },
    { id: 12, category: "الحفل الأول 2020", src: "https://i.imgur.com/S8Df0SQ.jpeg" },
   

    { id:10, category: "الحفل الثاني 2021", src: "https://i.imgur.com/C2A8sHa.jpeg" },
    { id:11, category: "الحفل الثاني 2021", src: "https://i.imgur.com/bqzobcP.jpeg" },
    { id:12, category: "الحفل الثاني 2021", src: "https://i.imgur.com/0IzdZNr.jpeg" },
   

 
    
  ];
  
  const categories = ["الحفل الأول 2020","الحفل الثاني 2021"];
  const [filter, setFilter] = useState("الحفل الأول 2020");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

 
  return (
    <div>
    <Helmet>
      <title>
        "الحفل التطوعي السنوي "قنطرة
 </title>
    
    
    
    </Helmet>
    <Header />
    
    <body>

<HeroImg title="الحفل التطوعي السنوي 'قنطرة'" />
    <main >
    
    <div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <div className="responsive-container-block optionsContainer">
          {categories.map((category) => (
              <p
                key={category}
                className={`text-blk list ${filter === category ? "active" : ""}`}
                onClick={() => handleFilterChange(category)}
                data-filter={category}
              >
                {category}
              </p>
            ))}
          </div>
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
 
{/* <div class="main-container">
      <div class="container-gallery">
        <img src="/./assets/Images/QurtubaSum/e7781133-7fc1-41a6-a3b7-f3e53d6537cb.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/857f2011-ba5b-4d14-b473-f60537013d4c.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/IMG_7094.JPG" alt="unsplash" width="100%" height="458px" />
        </div>
        <div class="container-gallery">
          <img src="/./assets/Images/QurtubaSum/IMG_7184.JPG" alt="This is an image" width="100%" />
          <img src="/./assets/Images/QurtubaSum/IMG_7185.JPG" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/QurtubaSum/IMG_7825.JPG" alt="unsplash" width="100%" height="520px"/>

         
          </div>
          <div class="container-gallery">
            <img src="/./assets/Images/QurtubaSum/IMG_7195.JPG" alt="This is an image" width="100%" />
            <img src="/./assets/Images/QurtubaSum/IMG_7402.JPG" alt="This is an image" width="100%"  />
            <img src="/./assets/Images/QurtubaSum/IMG_7404.JPG" alt="unsplash" width="100%" height="397px"/>

                  </div>
                  </div> */}
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default AnnualCer
