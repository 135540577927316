
import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
import "./gallery.css";

const Eatemad = () => {
  const images = [
    { id: 1, category: "one", src: "/./assets/Images/Eatemad/5ef2589c-791e-40e5-860a-c3ad0d63bf0c.jpg" },
    { id: 9, category: "one", src: "/./assets/Images/Eatemad/d85e71ad-730a-49da-82a0-c14c2a3af2b2.jpg" },
    { id: 10, category: "one", src: "/./assets/Images/Eatemad/IMG_1288.jpg" },

    { id: 2, category: "one", src: "/./assets/Images/Eatemad/IMG_2545.jpg" },
    { id: 3, category: "one", src: "/./assets/Images/Eatemad/D4B8A14F-9F33-4D54-AE9E-29D500C5262F.JPG" },
    { id: 4, category: "one", src: "/./assets/Images/Eatemad/bccf7a35-387e-4f59-9f15-c7a8abd32f4c.jpg" },
    { id: 5, category: "one", src: "/./assets/Images/Eatemad/775fa9ac-a37b-44c8-b287-d32b59daa9c6.jpg" },
    { id: 6, category: "one", src: "/./assets/Images/Eatemad/64ddea1a-0d87-4b66-8915-4d8de4387ea5.jpg" },
    { id: 7, category: "one", src: "/./assets/Images/Eatemad/07bf8759-1bac-43f3-a83f-94d734f33182.jpg" },
  ];
  
  const categories = [ "one", "two", "three", "four", "five"];
  const [filter, setFilter] = useState("one");

  const handleFilterChange = (category) => {
    setFilter(category);
  }
  return (
    <div>
    <Helmet>
      <title>مبادرة اعتماد</title>
    
    
    
    </Helmet>
    <Header />
    
    <body >

    <HeroImg title="مبادرة اعتماد" />
    <main >

<div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
         
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Eatemad
