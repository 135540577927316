import React, { useState, useMemo } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"

const AnnualReports = () => {
  return (
    <div>
      <Helmet>
        <title>التقارير السنوية</title>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="التقارير السنوية" />
        <main>
          <br />
          {/* <div className="container-fluid">
          <div className="all-articles-container marged">
            <section className="latest-articles-parent yellow-b">
              <div className="latest-articles-cards-parent mb-50 flex gap-3">
              
<div class="card shadow-3 m-2" style={{height:"200px",textAlign:'right',flexDirection:"row",alignItems:"center",width:"400px"}}>
  <div class="card-body">

    <h5 class="card-title"></h5>
   
    <a href="../assets/downloadFiles/files/AnnualReport2020.pdf" target='_blank' class="btn btn-info1" style={{borderRadius:"6%"}}>عرض 
                     &nbsp; <i class="fa fa-eye"></i></a>
  </div>
  </div>
  <div class="card shadow-3 m-2" style={{height:"200px",textAlign:'right',flexDirection:"row",alignItems:"center",width:"400px"}}>
  <div class="card-body">
    <h5 class="card-title">التقرير السنوي 2022</h5>
   
    <a href="../assets/downloadFiles/files/AnnualReport2022.pdf" target='_blank' class="btn btn-info1" style={{borderRadius:"6%"}}>عرض 
                     &nbsp; <i class="fa fa-eye"></i></a>
  </div>
  </div>

  
                 
              </div>
             
            </section>

          </div>
        </div> */}

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="assets/downloadFiles/files/AnnualReport2020.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                      التقرير السنوي 2020-2021
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>

              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="assets/downloadFiles/files/annual2.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                      التقرير السنوي 2022
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="assets/downloadFiles/files/annual1.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                      التقرير السنوي 2023
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </main>
        <br />
        <br />
        <Footer />
      </body>
    </div>
  )
}
export default AnnualReports
