
import React, { Component } from "react";
import { Helmet } from "react-helmet";


const notFound = () => {

    return (
        <div>
            <Helmet>
                <title>الصفحة غير موجودة </title>

            </Helmet>
            <body >
                <div style={{ textAlign: "center" }}>
                    <img width="130px" src="/assets/Images/logo.svg" style={{ paddingTop: "20px" }} />
                    <h1 style={{ fontFamily: "sans-serif", fontSize: "14vw", textDecoration: "line-through", textDecorationColor: "#941924", marginBottom: "0.4em", color: "black" }}>404</h1>
                    <h2>عذراً الصفحة غير متوفرة</h2>
                    <h3>لم يتم العثور على الصفحة المطلوبة</h3>
                    <a onClick={() => { window.location.href = "/index" }} className="btn btn" style={{ backgroundColor: "#79121f", color: "white" }}>العودة للرئيسية</a>
                </div>
            </body>


        </div>
    )
};
export default notFound;