const Footer = () => {
  const myStyle = {
    margin: "4px",
    borderRadius: "50px",
    boxShadow: "0px 0px 2px #000",
    padding: "0.2em 0.4em",
    marginRight: "5px",
  }

  return (
    <div style={{ direction: "rtl" }}>
      <footer id="footer-page">
        <div class="footer-row">
          <div class="footer-logos">
           </div>
          <div className="location-footer text-right">
            <h3 className="f18 title-footer small1">
              <img className="d-inline pl-2" src="/assets/Images/location-dot-solid.svg"></img>
          
              عنوان الجمعية
            </h3>
            <h6 className="sub-title-footer xsmall">
              المملكة العربية السعودية <br /> الدمام - حي النزهة - شارع عثمان بن عفان 
            </h6>
          </div>
          <div className="contactUs-footer text-right small1">
            <h3 className=" title-footer small1">
            <img className="d-inline pl-2" src="/assets/Images/phone-solid.svg"></img>
              تواصل معنا
            
            </h3>

            <h6 className="sub-title-footer my-3 xsmall bold" style={{fontWeight:"bold"}}>
              الإدارة العامة: 
              <h6 className="f14 sub-title-footer xsmall ">
              واتس اب / 0538593166 
                </h6>
                <h6 className="f14 sub-title-footer xsmall ">
                البريد الإلكتروني / ceo@qurtuba.org.sa 
                </h6>
        
            </h6>
              <h6 className="sub-title-footer my-3 xsmall bold" style={{fontWeight:"bold"}}>
              العلاقات العامة: 
              <h6 className="f14 sub-title-footer xsmall ">
              واتس اب / 0546530039 
                </h6>
                <h6 className="f14 sub-title-footer xsmall ">
                البريد الإلكتروني / info@qurtuba.org.sa 
                </h6>
        
            </h6>
            
          
          </div>
          <div className="accountMoney-footer text-right">
            {" "}
            <h3 className="f18 title-footer small1">
            <img className="d-inline pl-2" src="/assets/Images/building-columns-solid.svg"></img>
              الحسابات البنكية</h3>
            <h6 className="sub-title-footer  xsmall">
              مصرف الإنماء{" "}
            </h6>
            <h6 className="f14 sub-title-footer xsmall">
           
              SA315000068290009001000
            </h6>
<br/>
           
          </div>
        </div>
        <hr />
        <div class="footer-copyright">
          <h6 className="sub-title-footer my-3 xsmall"> جميع الحقوق محفوظة لجمعية قرطبة للقراءة</h6>
        
        </div>
      </footer>
      
     
    </div>
  )
}

export default Footer
