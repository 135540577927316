import React from 'react';

const Buttons = ({ filterItem, menuItems, activeCategory }) => {
  const handleButtonClick = (category) => {
    filterItem(category);
  };

  return (
    <div className="d-flex justify-content-center my-4 flex-wrap">
      {menuItems.map((category, index) => (
        <button
          key={index}
          className={`btn mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2 ${activeCategory === category ? 'btn-filled' : 'btn-outline'}`}
          onClick={() => handleButtonClick(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
};

export default Buttons;
