import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"

const Header2 = () => {
  const openExternalLink = () => {
    const externalLink = 'https://store.qurtuba.org.sa';
    window.open(externalLink, '_blank');
  };
  const [refreshCount, setRefreshCount] = useState(0)
  // useEffect to perform the refresh action
  useEffect(() => {
    if (refreshCount === 1) {
      console.log("Component refreshed once!")

      // You can put any logic here to refresh the component or fetch new data
      // ...

      // Reset refresh count to avoid multiple refreshes
      setRefreshCount(0)
    }
  }, [refreshCount])
  
  // Trigger the refresh once when the component mounts
  useEffect(() => {
    setRefreshCount(1)
  }, [])
  return (
    <div>
      <Helmet>

        <link type="text/css" rel="stylesheet" href="/./assets/css/home.min.css" />
      </Helmet>
      <nav class="navbar navbar-expand-lg " style={{backgroundColor:"white"}}>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse " id="navbarTogglerDemo01">
      <a class="navbar-brand" href="#">
     
          <img class="img1"width="100px" src="/assets/Images/logo.svg" />
        
      </a>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
        <li class="nav-item active ">
          <a class="nav-link" href="#" onClick={() => { window.location.href = "/"}}>الرئيسية <span class="sr-only">(current)</span></a>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            من نحن
          </a>
          <ul class="dropdown-menu " aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#"  onClick={() => { window.location.href = "/AboutUs" }}>قصتنا</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/Objectives2_2030" }}>أهدافنا ورؤية 2030م</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/OrgChart"}}>الهيكل التنظيمي</a></li>
            <li><a class="dropdown-item" href="#" onClick={() => {  window.location.href = "/StrategicDesign"}}> البناء الإستراتيجي </a></li>
            <li><a class="dropdown-item" href="#" onClick={() => {   window.location.href = "/GeneralMembers"}}>  الجمعية العمومية </a></li>
            <li><a class="dropdown-item" href="#" onClick={() => {   window.location.href = "/Administrations"}}>   مجلس الإدارة </a></li>
            <li><a class="dropdown-item" href="#" onClick={() => {    window.location.href = "/MeetingMinutes"}}>    محضر الإجتماعات </a></li>
            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/StandingCommittess"}}>اللجان الدائمة </a></li>
            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/Executive"}}>  الإدارة التنفيذية  </a></li>

            <li class="dropdown-submenu"><a class="dropdown-item dropdown-toggle" href="#"> الحوكمة والشفافية </a>

              <ul class="dropdown-menu  ">
                <li><a class="dropdown-item" href="#" onClick={() => {window.location.href = "/GovFiles"}}>اللوائح والصلاحيات</a></li>
                <li><a class="dropdown-item" href="#" onClick={() => {window.location.href = "/policy"}}>السياسات</a></li>
                <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/FinReports"}}>التقارير المالية</a></li>
                <li><a class="dropdown-item" href="#" onClick={() => {window.location.href = "/license"}}>التراخيص</a></li>
                <li><a class="dropdown-item" href="#" onClick={() => {window.location.href = "/disclosure"}}>الإفصاح</a></li>


              </ul>
            </li>
            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/OurPartners"}}>  شركاؤنا </a></li>

          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" onClick={() => { window.location.href = "/membership"}}>العضويات</a>
        </li>
       
     <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
         البرامج والمشاريع
          </a>
          <ul class="dropdown-menu  " aria-labelledby="navbarDropdownMenuLink">
            <li><a class="dropdown-item" href="#"  onClick={() => { window.location.href = "/eduPorgrams"}}>برامج نشر ثقافة القراءة</a></li>
            <li><a class="dropdown-item" href="#"  onClick={() => { window.location.href = "/empPorgrams"}}>برامج التمكين</a></li>
            
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      مكتبة قرطبة
          </a>
          <ul class="dropdown-menu  " aria-labelledby="navbarDropdownMenuLink">
          <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/library"}}>عن المكتبة</a></li>

            <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/programs"}}>برامج المكتبة</a></li>
          
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    المركز الإعلامي
          </a>
          <ul class="dropdown-menu  " aria-labelledby="navbarDropdownMenuLink">
          <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/News"}}>الأخبار</a></li>
          <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/annualReports"}}>التقارير السنوية</a></li>
          <li><a class="dropdown-item" href="#" onClick={() => {  window.location.href = "/gallery"}}>الصور</a></li>
          <li><a class="dropdown-item" href="#" onClick={() => {  window.location.href = "/podcast"}}>الصوتيات</a></li>

          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  وحدة التطوع
          </a>
          <ul class="dropdown-menu  " aria-labelledby="navbarDropdownMenuLink">
          <li><a class="dropdown-item" href="#" onClick={() => {   window.location.href = "/volunteers"}}>فريق قنطرة التطوعي</a></li>
          <li><a class="dropdown-item" href="#" onClick={() => { window.location.href = "/changemaking"}}>قرطبة وصناعة التغيير</a></li>
          
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" onClick={() => { window.location.href = "/careers"}}>فرص وظيفية</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#"  onClick={() => { window.location.href = "/contactUs" }}>تواصل معنا</a>
        </li>
      </ul>
      <form class=" form-inline my-2 my-lg-0 justify-content-end align-items-center ">
            <div className="d-flex justify-content-end  align-items-end">
              <h6 style={{ fontSize: "16px" }} className="mr-1 ml-1">
                تابعنا على
              </h6>
              <a className="mr-1 ml-1" href="https://www.instagram.com/qurtubasa/?igshid=1i2dxsxb901qi">
              <i class="fa-brands fa-instagram custom-icon"></i>
              </a>

              <a className="mr-1 ml-1" href="https://www.youtube.com/@Qurtubasa">
              <i class="fa-brands fa-youtube custom-icon"></i>
              </a>

               <a className="mr-1 ml-1" href="https://twitter.com/i/flow/login?redirect_after_login=%2Fqurtubasa">
               <i class="fa-brands fa-x-twitter custom-icon " style={{fontSize:"15px"}}></i>
              </a>
              <a className="mr-1 ml-1" href="https://t.me/qurtubalibrary">
              <i class="fa-brands fa-telegram custom-icon" ></i>
              </a>
            </div>
            <button class=" btn-outline btn btn-outline-success my-2 my-sm-0"  onClick={openExternalLink}>
              المتجر الإلكتروني
            </button>
          </form>
    </div>
  </nav>
    </div>
  )
}
export default Header2
