import React, { Component } from "react"
import { Helmet } from "react-helmet"

const ComingSoon = () => {
  return (
    <div>
      <Helmet>
        <title>قريباً </title>
      </Helmet>

      <body>
        <div style={{ textAlign: "center", direction: "rtl" }}>
          <img width="130px" src="assets/Images/logo.svg" style={{ paddingTop: "20px" }} />
          <h1 style={{ paddingTop: "50px", fontSize: "10vw", marginBottom: "40px" }}>قريباً</h1>
          {/* <h2>عذراً الصفحة غير متوفرة حالياً ترقب..<br /> ستظهر لك قريباً</h2> */}
          <a
            onClick={() => {
              window.location.href = "/index"
            }}
            className="btn btn"
            style={{ backgroundColor: "#79121f", color: "white" }}
          >
            العودة للرئيسية
          </a>
        </div>
      </body>
    </div>
  )
}
export default ComingSoon
