import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Library = () => {
  return (
    <div>
      <Helmet>
        <title>عن المكتبة</title>

        <script type="text/javascript" src="/./assets/js/membership.js"></script>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="عن المكتبة" />
        <main>
          <div class="container">
            <section>
              <div class="row" style={{ textAlign: "right" }}>
                <div class="col-md-12">
                  <div class="section-heading">
                    <p className="small p-2">
                   مكتبة إثرائية عامة تحتضن القارئات بمختلف اهتماماتهن وميولهن القرائية ، مقدمة لهن مجموعة من المناشط والفعاليات القرائية في بيئة ملهمة محفزة للقراءة.
                    </p>
                    <br />
<h3 className="mb-4 library med-51">خدمات المكتبة</h3>
                  <p className="mb-5 libraryp small small ">
            القراءة :  ( خدمة عامة )   <br/>  
تم توفير بيئة قرائية ملهمة للقارئات حيث يمكن للمستفيدة اختيار الكتاب الذي تريده و قراءته داخل المكتبة 

                  </p>
                  <p className="mb-5 libraryp small">
                  الإعارة : ( خدمة خاصة لأعضاء الجمعية  ولها ضوابط خاصة )
  <br/>  
  يمكن للمستفيدة استعارة أي كتاب من الكتب المتوفرة لدينا حضورياً ، او الكترونيا عن طريق الموقع حيث سيتم توصيله لها و استلامه منها بعد الانتهاء ( فقط الدمام و الخبر ).

                  </p>
                  
                  <p className="mb-5 libraryp small">
                  الارشاد القرائي : <br/>
وهي من اهم الخدمات التي تضطلع بها المكتبة وتقدم للأفراد أو الجهات وتتمثل في توجيه القراء وارشادهم نحو المواد التي تقابل حاجاتهم الفعلية، والمبنية على مستوياتهم العلمية والثقافية، حيث تم تشكيل فريق من القراء المميزين والمعروفين ليستقبل استفساراتكم و يجيبكم عليها بأسرع وقت .

                  </p>
                  <p className="mb-5 libraryp small">
                  الاحاطة الجارية:<br/>
الاعلام السريع بكل جديد يضاف الى المكتبة واصدار قوائم بالموضوعات التي تهم الفئة المستهدفة  تتميز بتنوع التصنيفات و اختلاف المستويات ( مبتدئ – متوسط – متقدم ) .
  </p>
                    <br />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Library
