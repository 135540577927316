import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "./Header2";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import DataEmp from "./DataEmp";
import HeroImg from "./HeroImg";

const Prog = () => {
  const { name } = useParams();
  const defaultCategory = "دار أندلسية";
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (name === "prog1") {
      setSelectedCategory("prog1");
      setFilteredData(DataEmp.find(item => item.name === "prog1").list);
    } else {
      setSelectedCategory(defaultCategory);
      setFilteredData(DataEmp.find(item => item.category === defaultCategory).list);
    }
  }, [name]);

  const handleButtonClick = clickedName => {
    setSelectedCategory(clickedName);
    if (clickedName === "prog1") {
      setFilteredData(DataEmp.find(item => item.name === "prog1").list);
    } else {
      setFilteredData(DataEmp.find(item => item.category === clickedName).list);
    }
  };

  return (
    <div>
      <Helmet>
        <title>برامج التمكين</title>
      </Helmet>
      <Header />
      <HeroImg title="برامج التمكين" />
      <main>
        <div className="d-flex justify-content-center my-4 flex-wrap">
          {DataEmp.map((item) => (
            <button
              key={item.id}
              onClick={() => handleButtonClick(item.category)}
              className={
                selectedCategory === item.category
                  ? " mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2  active-button"
                  : "btn-outline btn btn-outline-success mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2"
              }
            >
              <span className="small"> {item.category}</span>
            </button>
          ))}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <div className="news">
            {filteredData.map((item, index) => (
              <div key={index} className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                <a href={item.link}>
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text name-card xsmall text-right" style={{ minHeight: "2lh" }}>
                      {item.title}
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </main>
      <br />
      <br />
      <Footer />
    </div>
  );
};

export default Prog;
