import React, { useState, useEffect } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"

import DataOurPartners from "./DataOurPartners"
import HeroImg from "./HeroImg"

const OurPartners = () => {
  const defaultCategory = "شركاء التأسيس" // Default category
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    // Filter the data based on the default category when the component mounts
    const defaultCategoryData = DataOurPartners.find(item => item.category === defaultCategory)

    if (defaultCategoryData) {
      setFilteredData(defaultCategoryData.list)
    }
  }, [defaultCategory])

  const handleButtonClick = category => {
    // Filter the data based on the selected category
    const filteredCategory = DataOurPartners.find(item => item.category === category)

    if (filteredCategory) {
      setFilteredData(filteredCategory.list)
      setSelectedCategory(category)
    }
  }

  return (
    <div>
      <Helmet>
        <title> شركاؤنا</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="شركاؤنا" />
        <main>
          <div className="d-flex justify-content-center my-4 flex-wrap">
            {DataOurPartners.map(item => (
              <button
                key={item.id}
                onClick={() => handleButtonClick(item.category)}
                className={
                  selectedCategory === item.category
                    ? " mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2  active-button"
                    : "btn-outline btn btn-outline-success mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2"
                }
              >
                <span class="small"> {item.category}</span>
              </button>
            ))}
          </div>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              {filteredData.map(item => {
                return (
                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <div
                      className="card-body gallery-img"
                      style={{
                        backgroundImage: `url("${item.link}")`,
                        width: "300px",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                      }}
                    ></div>
                  </div>
                )
              })}
            </div>
          </div>
        </main>
        <br />
        <br />
        <Footer />
      </body>
    </div>
  )
}
export default OurPartners
