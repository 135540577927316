import React from "react"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import GovFiles from "./Components/GovFiles"
import OrgChart from "./Components/OrgChart"
import Staff from "./Components/Staff"
import Administrations from "./Components/Administrations"
import StandingCommittess from "./Components/StandingCommittess"
import Objectives from "./Components/Objectives"
import Design from "./Components/Design2"
import Objectives2_2030 from "./Components/Objectives2_2030"
import notFound from "./Components/404"
import comingSoon from "./Components/comingSoon"
import AboutUs from "./Components/aboutUs"
import Policy from "./Components/policy"

import Volunteers from "./Components/Volunteers"
import Programs from "./Components/Programs1"
import News from "./Components/News"
import Header2 from "./Components/Header2"
import HomePage from "./Components/HomePage"
import IndexG from "./Components/Gallery/Index"
import Rawas from "./Components/Gallery/Rawas"
import QurtubaLib from "./Components/Gallery/QurtubaLib"
import Eatemad from "./Components/Gallery/Eatemad"
import Andlus from "./Components/Gallery/Andlus"
import Axeer from "./Components/Gallery/Axeer"
import AnnualCer from "./Components/Gallery/AnnualCer"
import QurtubaSum from "./Components/Gallery/QurtubaSum"
import Podcast from "./Components/Podcast"
import Meetings from "./Components/Gallery/Meetings"
import AnnualReports from "./Components/AnnualReports"
import FinReports from "./Components/FinReports"

import Prog from "./Components/Prog"
import ProgEdu from "./Components/ProgEdu"
import Changemaking from "./Components/Changemaking"
import OurPartners from "./Components/OurPartners"
import MembershipNew from "./Components/membershipNew"
import GeneralMembers from "./Components/GeneralMembers"
import Careers from "./Components/Careers"
import MeetingMinutes from "./Components/MeetingMinutes"
import contactUs from "./Components/contactUs"
import Executive from "./Components/Executive"
import license from "./Components/license"
import Library from "./Components/library"
import disclosure from "./Components/disclosure"
import { Card } from './Components/Card';
import Siah from "./Components/Gallery/siah"
import Events from "./Components/Gallery/Events"

function App() {
  const cardData = [
    {
      id: 1,
      category: "دار أندلسية",
      title: "تقرير دار أندلسية",
      img: "path/to/image1.jpg",
      link: "../assets/downloadFiles/files/andlus2.pdf"
    },
    {
      id: 2,
      category: "مجلس أندلسية",
      title: "تقرير مجلس أندلسية",
      img: "path/to/image2.jpg",
      link: "../assets/downloadFiles/files/andlusM.pdf"
    },
    // Add more objects for other cards as needed
  ];
  return (
    <BrowserRouter>
      <div>
        <Switch>
        <Route exact path="/homepage" component={HomePage} />
          <Route exact path="/news" component={News} />
          <Route exact path="/Executive" component={Executive} />
          <Route exact path="/license" component={license} />
          <Route path="/cardss" exact>
          <Card items={cardData} />
        </Route>
        <Route path="/prog/:title" component={Prog} />
          <Route exact path="/changemaking" component={Changemaking} />
          <Route exact path="/FinReports" component={FinReports} />
          <Route exact path="/careers" component={Careers} />
          <Route exact path="/MeetingMinutes" component={MeetingMinutes} />
          <Route exact path="/contactUs" component={contactUs} />
          <Route exact path="/eduPorgrams" component={ProgEdu} />
          <Route exact path="/empPorgrams" component={Prog} />
          <Route exact path="/podcast" component={Podcast} />

          <Route exact path="/gallery/galleryDetails/2" component={QurtubaLib} />
          <Route exact path="/gallery/galleryDetails/1" component={Rawas} />
          <Route exact path="/gallery/galleryDetails/3" component={Eatemad} />
          <Route exact path="/gallery/galleryDetails/5" component={Andlus} />
          <Route exact path="/gallery/galleryDetails/6" component={Axeer} />
          <Route exact path="/gallery/galleryDetails/7" component={AnnualCer} />
          <Route exact path="/gallery/galleryDetails/8" component={QurtubaSum} />
          <Route exact path="/gallery/galleryDetails/9" component={Meetings} />
          <Route exact path="/gallery/galleryDetails/12" component={Siah} />
          <Route exact path="/gallery/galleryDetails/10" component={Events} />


          <Route exact path="/library" component={Library} />
          
          <Route exact path="/disclosure" component={disclosure} />

          <Route exact path="/gallery" component={IndexG} />
          <Route exact path="/podcast" component={Podcast} />

          <Route exact path="/AnnualReports" component={AnnualReports} />

          <Route exact path="/GovFiles" component={GovFiles} />
          <Route exact path="/comingSoon" component={comingSoon} />
          <Route exact path="/StrategicDesign" component={Design} />
          <Route exact path="/Header2" component={Header2} />
          <Route exact path="/HomePage" component={HomePage} />
          <Route exact path="/" component={HomePage} />
          <Route exact path="/index" component={HomePage} />
          <Route exact path="/membership" component={MembershipNew} />

          <Route exact path="/policy" component={Policy} />
          <Route exact path="/memberwoker" component={comingSoon} />
          <Route exact path="/memberattributer" component={comingSoon} />
          <Route exact path="/volunteers" component={Volunteers} />
          <Route exact path="/programs" component={Programs} />
          <Route exact path="/AboutUs" component={AboutUs} />
          <Route exact path="/OrgChart" component={OrgChart} />
          <Route exact path="/Staff" component={Staff} />
          <Route exact path="/Administrations" component={Administrations} />
          <Route exact path="/StandingCommittess" component={StandingCommittess} />
          <Route exact path="/Objectives" component={Objectives} />
          <Route exact path="/Objectives2_2030" component={Objectives2_2030} />
          <Route exact path="/OurPartners" component={OurPartners} />
          <Route exact path="/GeneralMembers" component={GeneralMembers} />

          <Route component={notFound} />
        </Switch>
      </div>
    </BrowserRouter>
  )
}

export default App
