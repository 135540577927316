const DataEdu = [
  {
    category: "شركاء التأسيس",
    id: 1,
    list: [
      {
        link: "assets/Images/OurPartners/tasees1.png",
        title: "الراجحي الإنسانية",
      },
      {
        link: "assets/Images/OurPartners/tasees2.png",
        title: " المركز الوطني لتنمية القطاع غير الربحي",
      },
      {
        link: "assets/Images/OurPartners/tasees3.jpeg",
        title: "التصميم الداخلي",
      },
      {
        link: "assets/Images/OurPartners/tasees4.jpg",
        title: "السيف الخيرية",
      },
      {
        link: "assets/Images/OurPartners/tasees5.jpeg",
        title: "مؤسسة عبدالله الجميح",
      },
      {
        link: "assets/Images/OurPartners/tasees6.jpg",
        title: " وكالة البعيجان",
      },
      {
        link: "assets/Images/OurPartners/tasees7.png",
        title: " الموارد البشرية",
      },
      {
        link: "assets/Images/OurPartners/tasees8.jpeg",
        title: "مكتبة المتنبي",
      },
      {
        link: "assets/Images/OurPartners/tasees9.JPG",
        title: "هاء",
      },
    ],
  },
  {
    category: "شركاء النجاح",
    id: 2,
    list: [
      {
        link: "assets/Images/OurPartners/success1.png",
      },
      {
        link: "assets/Images/OurPartners/success2.jpg",
      },
      {
        link: "assets/Images/OurPartners/success3.jpg",
      },
      {
        link: "assets/Images/OurPartners/success4.jpg",
      },
      {
        link: "assets/Images/OurPartners/success5.jpg",
      },
      {
        link: "assets/Images/OurPartners/success6.jpg",
      },
      {
        link: "assets/Images/OurPartners/success7.jpg",
      },
      {
        link: "assets/Images/OurPartners/success8.jpg",
      },
      {
        link: "assets/Images/OurPartners/success9.png",
      },
      {
        link: "assets/Images/OurPartners/success10.png",
      },
      {
        link: "assets/Images/OurPartners/success11.png",
      },
      {
        link: "assets/Images/OurPartners/success12.png",
      },
      {
        link: "assets/Images/OurPartners/success13.png",
      },
      {
        link: "assets/Images/OurPartners/success14.png",
      },
      {
        link: "https://i.imgur.com/7kszYEo.png",
      },
    ],
  },
  {
    category: "شراكات مجتمعية",
    id: 3,
    list: [
      {
        link: "assets/Images/OurPartners/Community1.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community2.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community3.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community4.png",
      },
      {
        link: "assets/Images/OurPartners/Community5.png",
      },
      {
        link: "assets/Images/OurPartners/Community6.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community7.png",
      },
      {
        link: "assets/Images/OurPartners/Community8.png",
      },
      {
        link: "assets/Images/OurPartners/Community9.png",
      },
      {
        link: "assets/Images/OurPartners/Community10.png",
      },
      {
        link: "assets/Images/OurPartners/Community11.png",
      },
      {
        link: "assets/Images/OurPartners/Community12.png",
      },
      {
        link: "assets/Images/OurPartners/Community13.gif",
      },
      {
        link: "assets/Images/OurPartners/Community14.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community15.png",
      },
      {
        link: "assets/Images/OurPartners/Community16.png",
      },
      {
        link: "assets/Images/OurPartners/Community17.png",
      },
      {
        link: "assets/Images/OurPartners/Community18.png",
      },
      {
        link: "assets/Images/OurPartners/Community19.png",
      },
      {
        link: "assets/Images/OurPartners/Community20.jpg",
      },
      {
        link: "assets/Images/OurPartners/Community21.png",
      },
      {
        link: "assets/Images/OurPartners/Community22.png",
      },
      {
        link: "assets/Images/OurPartners/Community23.png",
      },
      {
        link: "assets/Images/OurPartners/Community24.png",
      },
      {
        link: "assets/Images/OurPartners/Community25.png",
      },

      {
        link: "assets/Images/OurPartners/community28.webp",
      },
      {
        link: "assets/Images/OurPartners/community29.jpg",
      },
      {
        link: "assets/Images/OurPartners/community30.jpg",
      },
      {
        link: "assets/Images/OurPartners/community26.jpg",
      },
      {
        link: "assets/Images/OurPartners/community27.jpg",
      },
    ],
  },
  {
    category: "مذكرات التفاهم ",
    id: 4,
    list: [
      {
        link: "assets/Images/OurPartners/initiative1.jpg",
      },
      {
        link: "assets/Images/OurPartners/initiative2.png",
      },
    ],
  },
]
export default DataEdu
