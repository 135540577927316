import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const OrgChart = () => {
  return (
    <div>
      <Helmet>
        <title>الهيكل التنظيمي </title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="الهيكل التنظيمي" />
        <main>
          <div class="container">
            <div class="row">
              <div class="col">
                <embed src="./assets/Images/OrgChart2.svg" class="img-fluid" alt="..." />
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default OrgChart
