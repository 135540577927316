import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
const QurtubaSum = () => {

  const images = [
    { id: 1, category: "المسار الفني", src: "https://i.imgur.com/sg3ZJoL.jpeg" },
    { id: 2, category: "المسار الفني", src: "https://i.imgur.com/cIeMhL9.jpeg" },
    { id: 3, category: "المسار الفني", src: "https://i.imgur.com/i09xB9V.jpeg" },
    { id: 4, category: "المسار الفني", src: "https://i.imgur.com/vhuxqVF.jpeg" },
    { id: 5, category: "المسار الفني", src: "https://i.imgur.com/ug7dZkK.jpeg" },
    { id: 6, category: "المسار الفني", src: "https://i.imgur.com/yySpGoF.jpeg" },
    { id: 7, category: "المسار الفني", src: "https://i.imgur.com/LAh0X2s.jpeg" },
    { id: 8, category:"المسار الفني", src: "https://i.imgur.com/ffM9GpF.jpeg" },
    { id: 9, category: "المسار الفني", src: "https://i.imgur.com/xEoLypg.jpeg" },
    { id: 10, category: "المسار الفني", src: "https://i.imgur.com/9xnPSrN.jpeg" },
    { id:11, category: "المسار الفني", src: "https://i.imgur.com/uT6C17H.jpeg" },
    { id:12, category: "المسار الفني", src: "https://i.imgur.com/F9lw0qQ.jpeg" },
    { id:13, category: "المسار الفني", src: "https://i.imgur.com/TfAYdNi.jpeg" },
    { id:14, category: "المسار الفني", src: "https://i.imgur.com/rnbLM2I.jpeg" },
    { id:15, category: "المسار الفني", src: "https://i.imgur.com/bvRRgQa.jpeg" },


    { id:16, category: "المسار الثقافي", src: "https://i.imgur.com/N1nChPZ.jpeg" },
    { id:17, category: "المسار الثقافي", src: "https://i.imgur.com/ufZVk3W.jpeg" },
    { id:18, category: "المسار الثقافي", src: "https://i.imgur.com/6IvHPar.jpeg" },
    { id:19, category: "المسار الثقافي", src: "https://i.imgur.com/47js9nd.jpeg" },
    { id:20, category: "المسار الثقافي", src: "https://i.imgur.com/2Xg25bX.jpeg" },
    { id:21, category: "المسار الثقافي", src: "https://i.imgur.com/lD0mYlt.jpeg" },
    { id:22, category: "المسار الثقافي", src: "https://i.imgur.com/pa9fpQs.jpeg" },
    { id:23, category: "المسار الثقافي", src: "https://i.imgur.com/zRstPVE.jpeg" },
    { id:24, category: "المسار الثقافي", src: "https://i.imgur.com/Zy9nIwG.jpeg" },
    { id:25, category: "المسار الثقافي", src: "https://i.imgur.com/hnDaSS4.jpeg" },
    { id:26, category: "المسار الثقافي", src: "https://i.imgur.com/dIxZXXR.jpeg" },
    { id:27, category: "المسار الثقافي", src: "https://i.imgur.com/9ATPQdj.jpeg" },


    { id:28, category: "المسار الإعلامي", src: "https://i.imgur.com/gzkt2nk.jpeg" },
    { id:29, category: "المسار الإعلامي", src: "https://i.imgur.com/Gs4E7rh.jpeg" },
    { id:30, category: "المسار الإعلامي", src: "https://i.imgur.com/MJbhzNQ.jpeg" },
    { id:31, category: "المسار الإعلامي", src: "https://i.imgur.com/t9YniOM.jpeg" },
    { id:32, category: "المسار الإعلامي", src: "https://i.imgur.com/WdMmvCy.jpeg" },
    { id:33, category: "المسار الإعلامي", src: "https://i.imgur.com/jyKAqh1.jpeg" },
    { id:34, category: "المسار الإعلامي", src: "https://i.imgur.com/b1nEN5E.jpeg" },
    { id:35, category: "المسار الإعلامي", src: "https://i.imgur.com/tYrl7Iq.jpeg" },

  ];
  
  const categories = [ "المسار الفني", "المسار الثقافي", "المسار الإعلامي"];
  const [filter, setFilter] = useState("المسار الفني");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

 
  return (
    <div>
    <Helmet>
      <title> صيف قرطبة </title>
    
    
    
    </Helmet>
    <Header />
    
    <body>

<HeroImg title="صيف قرطبة" />
    <main >
    
    <div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <div className="responsive-container-block optionsContainer">
          {categories.map((category) => (
              <p
                key={category}
                className={`text-blk list ${filter === category ? "active" : ""}`}
                onClick={() => handleFilterChange(category)}
                data-filter={category}
              >
                {category}
              </p>
            ))}
          </div>
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
 
{/* <div class="main-container">
      <div class="container-gallery">
        <img src="/./assets/Images/QurtubaSum/e7781133-7fc1-41a6-a3b7-f3e53d6537cb.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/857f2011-ba5b-4d14-b473-f60537013d4c.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/IMG_7094.JPG" alt="unsplash" width="100%" height="458px" />
        </div>
        <div class="container-gallery">
          <img src="/./assets/Images/QurtubaSum/IMG_7184.JPG" alt="This is an image" width="100%" />
          <img src="/./assets/Images/QurtubaSum/IMG_7185.JPG" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/QurtubaSum/IMG_7825.JPG" alt="unsplash" width="100%" height="520px"/>

         
          </div>
          <div class="container-gallery">
            <img src="/./assets/Images/QurtubaSum/IMG_7195.JPG" alt="This is an image" width="100%" />
            <img src="/./assets/Images/QurtubaSum/IMG_7402.JPG" alt="This is an image" width="100%"  />
            <img src="/./assets/Images/QurtubaSum/IMG_7404.JPG" alt="unsplash" width="100%" height="397px"/>

                  </div>
                  </div> */}
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default QurtubaSum
