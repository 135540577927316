import React, { useState, useEffect } from "react"

function Counter({ maxCount }) {
  const [count, setCount] = useState()

  const counter = (minimum, maximum) => {
    for (let i = minimum; i <= maximum; ++i) {
      setTimeout(() => {
        setCount(i)
      }, 100)
    }
  }

  useEffect(() => {
    counter(0, maxCount)
  }, [0, maxCount])

  return (
    <div>
      <h2 style={{ color: "#79121F" }}>
        {count}
        <br />
      </h2>
    </div>
  )
}

export default Counter
