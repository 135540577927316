const DataEdu = 
[ 
  {
   "category": "رواح",
   "id": 1,
   "list": [{
    "link": "../assets/downloadFiles/files/rawah1.pdf",
    "title": "التقرير الختامي لبرنامج رواح- 1",
     
   },
   {
    "link": "../assets/downloadFiles/files/rawah2.pdf",
    "title": "التقرير الختامي لبرنامج رواح- 2",
     
   },
    {
       "link": "../assets/downloadFiles/files/rawah3.pdf",
       "title": "التقرير الختامي لبرنامج رواح- 3",
        
        
   }]
},
{
 "category": "سقيفة",
 "id": 2,
 "list": [{
     "link": "../assets/downloadFiles/files/saq1.pdf",
     
     "title": "التقرير الختامي لبرنامج سقيفة",
      
 }]
},
{
    "category": "رواس",
    "id": 4,
    "list": [{
        "link": "../assets/downloadFiles/files/rawas.pdf",
        
        "title": "تقرير مشروع رواس - عام 2023م الموافق 1445هـ",
         
    }]
   },
{
  "category": "سياح",
  "id": 3,
  "list": [{
      "link":  "../assets/downloadFiles/files/siah1.pdf",
      "title":  "التقرير الختامي لبرنامج سياح",
       
  }]
 }
]
export default DataEdu
// const DataEdu = [
//   {
//     id: "1",
//     title: "التقرير الختامي لبرنامج رواح- 1",
//     category: "رواح",
//     type:"برامج نشر الثقافة",
//     link: "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 1) .pdf",
//   },
//   {
//     id: "2",
//     title: "التقرير الختامي لبرنامج رواح- 2",
//     category: "رواح",
//     type:"برامج نشر الثقافة",
//     link: "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 2) .pdf",
//   },
//   {
//     id: "3",
//     title: "التقرير الختامي لبرنامج رواح- 3",
//    
//     link: "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 3) .pdf",
//   },
//   {
//     id: "4",
//     title: "التقرير الختامي لبرنامج سياح",
//     category: "سياح",
//     type:"برامج نشر الثقافة",
//     link: "../assets/downloadFiles/files/التقرير الختامي لبرنامج (سيّاح) .pdf",
//   },
//   {
//     id: "5",
//     title: "التقرير الختامي لبرنامج سقيفة",
//     category: "سقيفة",
//     type:"برامج نشر الثقافة",
//     link: "../assets/downloadFiles/files/التقرير الختامي لبرنامج (سقيفة).pdf",
//   },

//   {
//     id: "6",
//     title: "تقرير أندلسية الموسم الأول",
//     category: "أندلسية",
//     type:"برامج التمكين ",
//     link: "../assets/downloadFiles/files/نسخة من تقرير اندلسية الموسم الأول .pdf",
//   },
//   {
//     id: "7",
//     title: "",
//     category: "إكسير",
//     type:"برامج التمكين ",
//     link: "",
//   },
// ]

// export default DataEdu

//  const DataEdu2 = [ 
//    {
//     "category": "أندلسية",
//     "id": 1,
//     "list": [{
//         "link": "../assets/downloadFiles/files/نسخة من تقرير اندلسية الموسم الأول .pdf",
//         "title": "تقرير أندلسية الموسم الأول",
         
//     }]
// },
// {
//   "category": "إكسير",
//   "id": 2,
//   "list": [{
//       "link": "",
//       "title": "",
       
//   }]
// }
// ]
// const  DataEmp= [ 
//   {
//    "category": "رواح",
//    "id": 1,
//    "list": [{
//     "link": "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 1) .pdf",
//     "title": "التقرير الختامي لبرنامج رواح- 1",
     
//    },
//    {
//     "link": "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 2) .pdf",
//     "title": "التقرير الختامي لبرنامج رواح- 2",
     
//    },
//     {
//        "link": "../assets/downloadFiles/files/التقرير الختامي لبرنامج (رواح - 3) .pdf",
//        "title": "التقرير الختامي لبرنامج رواح- 3",
        
        
//    }]
// },
// {
//  "category": "سقيفة",
//  "id": 2,
//  "list": [{
//      "link": "التقرير الختامي لبرنامج سقيفة",
//      "title": "../assets/downloadFiles/files/التقرير الختامي لبرنامج (سقيفة).pdf",
      
//  }]
// },
// {
//   "category": "سياح",
//   "id": 3,
//   "list": [{
//       "link":  "../assets/downloadFiles/files/التقرير الختامي لبرنامج (سيّاح) .pdf",
//       "title":  "التقرير الختامي لبرنامج سياح",
       
//   }]
//  }
// ]