import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"

const GeneralMembers = () => {
  return (
    <div>
      <Helmet>
        <title> أعضاء الجمعية العمومية</title>
      </Helmet>
      <Header />
      <body style={{backgroundColor:"white"}}>

        <HeroImg title="أعضاء الجمعية العمومية" />
        <main className="white-bg">
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>عذاري محمد العرفج</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>إسراء سليمان الشبيب</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> أروى خالد الزاهد</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> نهى حميد المستادي</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>

            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> د.جواهر محمد الغامدي</b> - عضو الجمعية العمومية
                  <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> د.نهى عبداللطيف الملا</b> - عضو الجمعية العمومية
                  <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> د.إنعام منصور الدليجان</b>- عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> أمل بنت أحمد الجعفري</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b>علي صالح الحميدان</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <b> هبة جمال الحامد</b> - عضو الجمعية العمومية <br />
                </p>
              </div>
            </div>
          </div>
          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default GeneralMembers
