import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from "./Header2";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import HeroImg from "./HeroImg";

const ContactUs = () => {
  const history = useHistory();

  const baseUrl = "http://localhost:8000";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "استفسار",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // const response = await fetch(`${baseUrl}/email/sendEmail`, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //   },
      //   body: JSON.stringify(formData),
      // });

      // if (response.ok) {
        toast.success("تم إرسال الرسالة بنجاح");
       
      
      //   console.log("Email sent successfully!");
      // } else {
      //   toast.error("حدث خطأ أثناء إرسال الرسالة");
      // }
    } catch (error) {
      console.error("Error sending form data", error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  return (
    <div>
      <Helmet>
        <title> تواصل معنا</title>
      </Helmet>
      <Header />

      <body>
      <ToastContainer />

        <HeroImg title="تواصل معنا" />
        <main>
          <div className="container">
            <section className="py-3 py-md-5">
              <div className="container">
                <div className="row justify-content-md-center">
                  <div className="col-12 col-md-10 col-lg-8 col-xl-7 col-xxl-6"></div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-lg-center">
                  <div className="col-12 col-lg-9">
                    <div className="bg-white border rounded shadow-sm overflow-hidden">
                      <form onSubmit={handleSubmit}>
                        <div className="row gy-4 gy-xl-5 p-4 p-xl-5">
                          <div className="col-12 col-md-6" style={{ textAlign: "right" }}>
                            <label htmlFor="name" className="form-label">
                              الاسم<span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                style={{ textAlign: "right" }}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-12 col-md-6" style={{ textAlign: "right" }}>
                            <label htmlFor="email" className="form-label">
                              البريد الإلكتروني <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>

                          <div className="col-12" style={{ textAlign: "right", marginTop: "10px" }}>
                            <div className="form-group">
                              <label htmlFor="exampleFormControlSelect1">التواصل بخصوص </label>
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                name="subject"
                                onChange={handleChange}
                              >
                                <option value="استفسار">استفسار</option>
                                <option value="شكوى">شكوى</option>
                                <option value="أخرى">أخرى</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-12" style={{ textAlign: "right" }}>
                            <label htmlFor="message" className="form-label">
                              نص الرسالة <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows="7"
                              onChange={handleChange}
                              required
                            ></textarea>
                          </div>
                          <div className="col-12">
                            <div className="d-grid">
                              <button className="btn btn-primary btn-lg" type="submit" style={{ width: "100%",backgroundColor:"#79121F" }}>
                                إرسال
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  );
};

export default ContactUs;
