import React, { useState, useMemo } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"

const FinReports = () => {
  return (
    <div>
      <Helmet>
        <title>التقارير المالية</title>
      </Helmet>
      <Header />

      <body>
        <HeroImg title="التقارير المالية" />
        <main>
          <br />
         

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/2019.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                    القوائم المالية وتقرير مراجع الحسابات المستقل لعام 2019م
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>

              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/2020.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                    القوائم المالية وتقرير مراجع الحسابات المستقل لعام 2020م
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/2021.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                    القوائم المالية وتقرير مراجع الحسابات المستقل لعام 2021م
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
              <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)" }}>
                <a href="../assets/downloadFiles/files/2022.pdf" target="_blank">
                  <div
                    className="card-body gallery-img"
                    style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")` }}
                  ></div>
                  <div className="card-footer text-left">
                    <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                    القوائم المالية وتقرير مراجع الحسابات المستقل لعام 2022م
                    </p>
                    <p className="card-text s16 detail-card xsmall">
                      عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                    </p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </main>
        <br />
        <br />
        <Footer />
      </body>
    </div>
  )
}
export default FinReports
