import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
const Axeer = () => {

  const images = [
    { id: 1, category: "حفل الافتتاح", src: "https://i.imgur.com/nUoCJSM.jpeg" },
    { id: 2, category: "حفل الافتتاح", src: "https://i.imgur.com/4Tomwa2.jpeg" },
    { id: 3, category: "حفل الافتتاح", src: "https://i.imgur.com/WE1Lgdg.jpeg" },
    { id: 4, category: "حفل الافتتاح", src: "https://i.imgur.com/n9hsg31.jpeg" },
    { id: 5, category: "حفل الافتتاح", src: "https://i.imgur.com/fgpZKZF.jpeg" },
    { id: 6, category: "حفل الافتتاح", src: "https://i.imgur.com/DpsJzu6.jpeg" },
    { id: 7, category: "حفل الافتتاح", src: "https://i.imgur.com/dwnIm5P.jpeg" },
    { id: 8, category:"حفل الافتتاح", src: "https://i.imgur.com/5ieEtng.jpeg" },
    { id: 9, category: "حفل الافتتاح", src: "https://i.imgur.com/CiBHT8Y.jpeg" },
   
   

    { id:10, category: "الحفل الختامي", src: "https://i.imgur.com/nOYu17U.jpeg" },
    { id:11, category: "الحفل الختامي", src: "https://i.imgur.com/CmNFCbR.jpeg" },
    { id:12, category: "الحفل الختامي", src: "https://i.imgur.com/4P7P8UF.jpeg" },
    { id:13, category: "الحفل الختامي", src: "https://i.imgur.com/mx0Mb3M.jpeg" },
    { id:14, category: "الحفل الختامي", src: "https://i.imgur.com/IhF0O2i.jpeg" },
    { id:15, category: "الحفل الختامي", src: "https://i.imgur.com/bd3ixrt.jpeg" },

 
    
  ];
  
  const categories = ["حفل الافتتاح","الحفل الختامي"];
  const [filter, setFilter] = useState("حفل الافتتاح");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

 
  return (
    <div>
    <Helmet>
      <title> برنامج إكسير</title>
    
    
    
    </Helmet>
    <Header />
    
    <body>

<HeroImg title="برنامج إكسير" />
    <main >
    
    <div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <div className="responsive-container-block optionsContainer">
          {categories.map((category) => (
              <p
                key={category}
                className={`text-blk list ${filter === category ? "active" : ""}`}
                onClick={() => handleFilterChange(category)}
                data-filter={category}
              >
                {category}
              </p>
            ))}
          </div>
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
 
{/* <div class="main-container">
      <div class="container-gallery">
        <img src="/./assets/Images/QurtubaSum/e7781133-7fc1-41a6-a3b7-f3e53d6537cb.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/857f2011-ba5b-4d14-b473-f60537013d4c.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/IMG_7094.JPG" alt="unsplash" width="100%" height="458px" />
        </div>
        <div class="container-gallery">
          <img src="/./assets/Images/QurtubaSum/IMG_7184.JPG" alt="This is an image" width="100%" />
          <img src="/./assets/Images/QurtubaSum/IMG_7185.JPG" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/QurtubaSum/IMG_7825.JPG" alt="unsplash" width="100%" height="520px"/>

         
          </div>
          <div class="container-gallery">
            <img src="/./assets/Images/QurtubaSum/IMG_7195.JPG" alt="This is an image" width="100%" />
            <img src="/./assets/Images/QurtubaSum/IMG_7402.JPG" alt="This is an image" width="100%"  />
            <img src="/./assets/Images/QurtubaSum/IMG_7404.JPG" alt="unsplash" width="100%" height="397px"/>

                  </div>
                  </div> */}
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Axeer
