import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
import "./gallery.css";

const Siah = () => {
  const images = [
    { id: 1, category: "one", src: "https://i.imgur.com/GlL4DdY.jpeg" },
    { id: 9, category: "one", src: "https://i.imgur.com/mgrbWKJ.jpeg" },
    { id: 10, category: "one", src: "https://i.imgur.com/CoPj822.jpeg" },

    { id: 2, category: "one", src: "https://i.imgur.com/co2jOE4.jpeg" },
    { id: 3, category: "one", src: "https://i.imgur.com/bTMBQjg.jpeg" },
    { id: 4, category: "one", src: "https://i.imgur.com/1h6jpvg.jpeg" },
    { id: 5, category: "one", src: "https://i.imgur.com/j8UgETz.jpeg" },
    { id: 6, category: "one", src: "https://i.imgur.com/fQaLp2X.jpeg" },
    { id: 7, category: "one", src: "https://i.imgur.com/NVCdiB2.jpeg" },
    { id: 8, category: "one", src: "https://i.imgur.com/jphOWEX.jpeg" },
    
    { id: 11, category: "one", src: "https://i.imgur.com/UVbsE42.jpeg" },
    { id: 13, category: "one", src: "https://i.imgur.com/rbo6gB4.jpeg" },
    { id: 14, category: "one", src: "https://i.imgur.com/1xW7iQ8.jpeg" },
    { id: 15, category: "one", src: "https://i.imgur.com/sQyBsFM.jpeg" },

    { id: 16, category: "one", src: "https://i.imgur.com/krwU6eM.jpeg" },
    { id: 17, category: "one", src: "https://i.imgur.com/R3BJiKn.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/VNEY3cG.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/5QmHzpZ.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/XLpmcnV.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/kob9zon.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/Jd7Qkik.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/277iK66.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/3UQBzML.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/Phl0Sxf.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/0du2Wu9.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/jLndluR.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/j10w7Hj.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/L2zXWdk.jpeg" },
    { id: 18, category: "one", src: "https://i.imgur.com/EMSfb4d.jpeg" },
  




   


  ];
  
  const categories = [ "one", "two", "three", "four", "five"];
  const [filter, setFilter] = useState("one");

  const handleFilterChange = (category) => {
    setFilter(category);
  }
  return (
    <div>
    <Helmet>
      <title>برنامج سياح</title>
    
    
    
    </Helmet>
    <Header />
    
    <body >

    <HeroImg title="برنامج سياح" />
    <main >

<div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
         
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Siah
