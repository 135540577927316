import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
import "./gallery.css";

const Rawas = () => {
  const images = [
    { id: 1, category: "one", src: "https://i.imgur.com/Vuiv6uD.jpeg" },
    { id: 9, category: "one", src: "https://i.imgur.com/C3FB70b.jpeg" },
    { id: 10, category: "one", src: "https://i.imgur.com/NBK3n8g.jpeg" },

    { id: 2, category: "one", src: "https://i.imgur.com/42IqYcI.jpeg" },
    { id: 3, category: "one", src: "https://i.imgur.com/7KEX6Io.jpeg" },
    { id: 4, category: "one", src: "https://i.imgur.com/pYU4T8b.jpeg" },
    { id: 5, category: "one", src: "https://i.imgur.com/7i4EIdo.jpeg" },
    { id: 6, category: "one", src: "https://i.imgur.com/Hqo30uk.jpeg" },
    { id: 7, category: "one", src: "https://i.imgur.com/2llDxXB.jpeg" },
    { id: 8, category: "one", src: "https://i.imgur.com/SoV8qYj.jpeg" },
  ];
  
  const categories = [ "one", "two", "three", "four", "five"];
  const [filter, setFilter] = useState("one");

  const handleFilterChange = (category) => {
    setFilter(category);
  }
  return (
    <div>
    <Helmet>
      <title>نادي رواس</title>
    
    
    
    </Helmet>
    <Header />
    
    <body >

    <HeroImg title="نادي رواس" />
    <main >

<div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
         
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Rawas
