

import React, { useState, useEffect } from "react";
import Data from "./Data2";
import Buttons from "./Buttons"; // Import the Buttons component
import { Card } from "./Card";
import Header from "./Header2";
import Footer from "./Footer";
import Counter from "./Counter";
import { Helmet } from "react-helmet";
import VideoModal from './VideoModal';


const HomePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const youtubeUrl = 'https://youtu.be/IlCcZoOLncw?si=AgUqllSavc0NTHA2'; // Replace with your YouTube video URL

  const [items, setItems] = useState([]);
  const [activeCategory, setActiveCategory] = useState("برامج التمكين"); // Default active category

  useEffect(() => {
    filterItems(activeCategory); // Filter items when the component mounts with the default category
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  // Define your Data2 here
  const Data2 = Data;

  const filterItems = (category) => {
    const filteredItems = Data2.filter((item) => item.category === category);
    setItems(filteredItems);
    setActiveCategory(category);
  };
  const [activeButton, setActiveButton] = useState("vision")

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  return (
    <div>

      <Helmet>
        <title>الصفحة الرئيسية </title>
      </Helmet>
      <Header />
      <div className="section1">
        
        <div className="row">
          <div className="align-self-center col-md-6 d-flex flex-column align-items-start">
            <h6 className="med-5" style={{ color: "#79121F" }}>
              جمعية قرطبة
            </h6>
            <h1 className="mt-4 med-2" style={{ textAlign: "right", alignSelf: "right" }}>
              {" "}
              بابك القريب <br /> لعبور عالم القراءة
            </h1>
            <h6 className="med-5"> نصل الماضي بالحاضر ونبني المستقبل</h6>
            <div className="mt-4 d-flex flex-row align-items-center">
              <a href="#aboutQurtuba-section">
                <button class="btn-filled btn btn-outline-success mx-1 my-3 my-sm-0" type="submit">
                  <span className="xsmall">تعرف علينا</span>
                </button>
              </a>
             <button
              onClick={openModal}
                
                class="btn-outline btn btn-outline-success mx-1 my-3 my-sm-0"
                
              >
                <span className="xsmall">مشاهدة الفيديو </span>
              </button> 

{/* <button onClick={openModal} className="open-button">Play Video</button> */}
<VideoModal 
        isOpen={modalIsOpen} 
        onRequestClose={closeModal} 
        videoUrl={youtubeUrl}
      />
            </div>
          </div>
          <div className="col-md-6">
            <img className="img-fluid my-sm-5" src="assets/Images/Capture1111121.PNG"></img>
          
          </div>
        </div>
        <div className="row">
          <div className="container" id="myGroup">
            <p className="text-center">
              <button
                className={`btn mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2 ${
                  activeButton === "vision" ? "btn-filled" : "btn-outline"
                }`}
                onClick={() => handleButtonClick("vision")}
                aria-expanded={activeButton === "vision"}
                aria-controls="collapseExample"
              >
                الرؤية
              </button>
              <button
                className={`btn mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2 ${
                  activeButton === "mission" ? "btn-filled" : "btn-outline"
                }`}
                onClick={() => handleButtonClick("mission")}
                aria-expanded={activeButton === "mission"}
                aria-controls="collapseExample2"
              >
                الرسالة
              </button>
            </p>
            <div
              className={`collapse ${activeButton === "vision" ? "show" : ""}`}
              id="collapseExample"
              data-parent="#myGroup"
            >
              <div className="card card-body text-center">الخيار الأول في بناء مجتمع قارئ ومثقف.</div>
            </div>
            <div
              className={`collapse ${activeButton === "mission" ? "show" : ""}`}
              id="collapseExample2"
              data-parent="#myGroup"
            >
              <div className="card card-body text-center">
                نسعى لنشر ثقافة القراءة بين الفتيات والمساهمة في تكوين نماذج واعية وفاعلة معتزة بهويتها من خلال بيئات
                جاذبة وبرامج مبتكرة.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="aboutQurtuba-section" id="aboutQurtuba-section">
        <div className="col-md-6 med-5 text-center">
          <h6 style={{ color: "#79121f" }}>نظرة عامة</h6>
          <h4 className="section__title med-4">عن قرطبة</h4>
        </div>
        <div className="col-md-6 text-center" style={{ paddingLeft: "500px" }}>
          <div class="wrapper4 text-center">
            <div class="slide-wrapper">
              <div class="carousel-rtl" dir="rtl">
                <img src="assets/Images/Quote11.png" height={250} width={300} alt="..." />
                <img src="assets/Images/Quote22.png" height={250} width={300} alt="..." />
                <img src="assets/Images/Quote33.png" height={250} width={300} alt="..." />
                <img src="assets/Images/Quote44.png" height={250} width={300} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="program-section">
        <div className="container-fluid">
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12">
              <h6 className="text-center" style={{ color: "#79121f" }}>
                تنوع وأصالة{" "}
              </h6>
              <h2 className="section__title med-4">برامجنا</h2>
          
              {/* Render buttons for filtering categories */}
              <Buttons filterItem={filterItems} menuItems={["برامج التمكين", "برامج المكتبة","برامج نشر ثقافة القراءة"]} activeCategory={activeCategory} />

              {/* Render cards */}
              <div className="row justify-content-center"> {/* Add justify-content-center class */}
                {items.map((item) => (
                  <div key={item.id} className="col-md-3 mb-4">
                    <Card item={item} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="statistic-section">
        <div className="col-12">
          <h6 className="text-center" style={{ color: "#79121f" }}>
            أرقام ونجاحات{" "}
          </h6>
          <h2 className="section__title med-4">إحصائيات قرطبة</h2>
        </div>
        <div className="container-flex" style={{ marginTop: "150px" }}>
          <div className="box med-51">
            <Counter maxCount={12468} />
            <h5 class="u-text u-text-3">الساعات التطوعية </h5>
          </div>
          <div className="box med-51">
            <Counter maxCount={67348} />
            <h5 class="u-text u-text-5">عدد المستفيدين </h5>
          </div>
          <div className="box med-51">
            <Counter maxCount={230} />
            <h5 class="u-align-center u-text u-text-7">عدد المتطوعين</h5>
          </div>
        </div>
      </div>

      <br />
      <h6 className="text-center" style={{ color: "#79121f" }}>
        أكثر من ٣٤ شريك{" "}
      </h6>
      <h2 className="section__title med-4" > 
                    <a onClick={() => {
                      window.location.href = "/OurPartners"
                    }}>شركاء النجاح</a>
                    </h2>
      <div class="wrapper3">
        <div class="slider">
          <div class="slide-track">
            {/* <div class="slide">
              <img src="/assets/Images/hawya.svg" />
            </div> */}

            <div class="slide">
              <img src="https://i.imgur.com/bEWY6Ab.png" />
            </div>

            <div class="slide">
              <img src="https://uploads-ssl.webflow.com/5ee0d0c8efb107a26419bf01/5ee0d97bc8ef9ba80c03b6fb_Logo_02.png" />
            </div>

            <div class="slide">
              <img
                src="https://www.alsaifcharity.org/wp-content/uploads/2019/07/%D8%A7%D9%84%D9%84%D9%88%D9%82%D9%88-%D8%B5%D8%BA%D9%8A%D9%8A%D8%B1.png"
                height={150}
                style={{ width: "100px" }}
              />
            </div>
           
            <div class="slide">
              <img src="assets/Images/OurPartners/success14.png" />
            </div>
            
            <div class="slide">
              <img src="https://i.imgur.com/bEWY6Ab.png" />
            </div>

            <div class="slide">
              <img src="https://uploads-ssl.webflow.com/5ee0d0c8efb107a26419bf01/5ee0d97bc8ef9ba80c03b6fb_Logo_02.png" />
            </div>

            <div class="slide">
              <img
                src="https://www.alsaifcharity.org/wp-content/uploads/2019/07/%D8%A7%D9%84%D9%84%D9%88%D9%82%D9%88-%D8%B5%D8%BA%D9%8A%D9%8A%D8%B1.png"
                height={150}
                style={{ width: "100px" }}
              />
            </div>
            <div class="slide">
              <img src="assets/Images/OurPartners/success11.png" />
            </div>
            {/* <div class="slide">
              <img src="/../assets/Images/hawya.svg" />
            </div> */}

            <div class="slide">
              <img src="https://i.imgur.com/bEWY6Ab.png" />
            </div>

            <div class="slide">
              <img src="https://uploads-ssl.webflow.com/5ee0d0c8efb107a26419bf01/5ee0d97bc8ef9ba80c03b6fb_Logo_02.png" />
            </div>

            <div class="slide">
              <img
                src="https://www.alsaifcharity.org/wp-content/uploads/2019/07/%D8%A7%D9%84%D9%84%D9%88%D9%82%D9%88-%D8%B5%D8%BA%D9%8A%D9%8A%D8%B1.png"
                height={150}
                style={{ width: "100px" }}
              />
            </div>
            <div class="slide">
              <img src="https://i.imgur.com/bEWY6Ab.png" />
            </div>

            <div class="slide">
              <img src="https://uploads-ssl.webflow.com/5ee0d0c8efb107a26419bf01/5ee0d97bc8ef9ba80c03b6fb_Logo_02.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-Header">
              <h5 class="modal-title" id="exampleModalLabel">
                تعرّف علينا
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div class="modal-body">
              <iframe
                width="560"
                height="315"
                src="assets/Images/video.mp4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>{" "}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline btn-outline-success" data-dismiss="modal">
                إغلاق
              </button>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
export default HomePage

