import React, { useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
const Events = () => {

  const images = [
    { id: 1, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/nepxUEY.jpeg" },
    { id: 2, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/Sq41fiN.jpeg" },
    { id: 3, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/es95cWl.jpeg" },
    { id: 4, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/KlaRMGd.jpeg" },
    { id: 5, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/AKdQL9G.jpeg" },
    { id: 6, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/Xveyy0T.jpeg" },
    { id: 7, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/4RvzY9d.jpeg" },
    { id: 8, category:"لقاء ديوانية السيف", src: "https://i.imgur.com/mpPrxXv.jpeg" },
    { id: 9, category: "لقاء ديوانية السيف", src: "https://i.imgur.com/rFLv6B6.jpeg" },
   
   
    { id: 10, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/yYdBzq7.jpeg" },
    { id:11, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/0wv37fZ.jpeg" },
    { id:12, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/D9U8elU.jpeg" },
    { id:13, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/5BpXX3u.jpeg" },
    { id:15, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/QlLnVyt.jpeg" },
    { id:16, category: "زيارة مؤسسة ديمة", src: "https://i.imgur.com/3iWgFyY.jpeg" },

   

    { id:17, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/zyLcPtt.jpeg" },
    { id:18, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/RCOamDp.jpeg" },
    { id:19, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/e6SHUNp.jpeg" },
    { id:20, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/bgFZts7.jpeg" },
    { id:21, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/euJKAal.jpeg" },
    { id:22, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/6dOidgn.jpeg" },
    { id:23, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/qecrRIp.jpeg" },
    { id:24, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/FSzPG4b.jpeg" },
    { id:25, category: "اللقاء الختامي لنادي قمارش", src: "https://i.imgur.com/AOyxgyV.jpeg" },
    
    { id:26, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/kItvMXE.jpeg" },
    { id:27, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/72eLzvF.jpeg" },
    { id:28, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/akaq16y.jpeg" },
    { id:29, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/G9AiDsa.jpeg" },
    { id:30, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/vBSalnA.jpeg" },
    { id:31, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/GVkAE7t.jpeg" },
    { id:32, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/bqH4gQ6.jpeg" },
    { id:33, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/jBTDu9h.jpeg" },
    { id:34, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/0XOkNsd.jpeg" },
    { id:35, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/DFfVSlD.jpeg" },
    { id:36, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/JGVJKbB.jpeg" },
    { id:37, category: "اللقاء الرمضاني الأول", src: "https://i.imgur.com/SZzpRSG.jpeg" },
 
    { id:38, category: "اللقاء الرمضاني الثاني", src: "https://i.imgur.com/Jvek1wG.jpeg" },
    { id:39, category: "اللقاء الرمضاني الثاني", src: "https://i.imgur.com/G42Tc7v.jpeg" },
    { id:40, category: "اللقاء الرمضاني الثاني", src: " https://i.imgur.com/UVZhK9l.jpeg " },
    { id:41, category: "اللقاء الرمضاني الثاني", src: "https://i.imgur.com/uNW4wKY.jpeg" },
    { id:42, category: "اللقاء الرمضاني الثاني", src: "https://i.imgur.com/J05JKuG.jpeg" },

    { id:43, category: "عيد قرطبة", src: "https://i.imgur.com/BKkhizk.jpeg" },
    { id:44, category: "عيد قرطبة", src: "https://i.imgur.com/oWv9MVR.jpeg" },
    { id:45, category: "عيد قرطبة", src: "https://i.imgur.com/7bLOzcu.jpeg" },
    { id:46, category: "عيد قرطبة", src: "https://i.imgur.com/9Dm28Qk.jpeg" },


    
  ];
  
  const categories = [  "لقاء ديوانية السيف","عيد قرطبة","زيارة مؤسسة ديمة","اللقاء الرمضاني الأول","اللقاء الرمضاني الثاني","اللقاء الختامي لنادي قمارش"];
  const [filter, setFilter] = useState("لقاء ديوانية السيف");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

 
  return (
    <div>
    <Helmet>
      <title> المناسبات الداخلية </title>
    
    
    
    </Helmet>
    <Header />
    
    <body>

<HeroImg title="المناسبات الداخلية" />
    <main >
    
    <div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <div className="responsive-container-block optionsContainer">
          {categories.map((category) => (
              <p
                key={category}
                className={`text-blk list ${filter === category ? "active" : ""}`}
                onClick={() => handleFilterChange(category)}
                data-filter={category}
              >
                {category}
              </p>
            ))}
          </div>
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
 
{/* <div class="main-container">
      <div class="container-gallery">
        <img src="/./assets/Images/QurtubaSum/e7781133-7fc1-41a6-a3b7-f3e53d6537cb.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/857f2011-ba5b-4d14-b473-f60537013d4c.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/QurtubaSum/IMG_7094.JPG" alt="unsplash" width="100%" height="458px" />
        </div>
        <div class="container-gallery">
          <img src="/./assets/Images/QurtubaSum/IMG_7184.JPG" alt="This is an image" width="100%" />
          <img src="/./assets/Images/QurtubaSum/IMG_7185.JPG" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/QurtubaSum/IMG_7825.JPG" alt="unsplash" width="100%" height="520px"/>

         
          </div>
          <div class="container-gallery">
            <img src="/./assets/Images/QurtubaSum/IMG_7195.JPG" alt="This is an image" width="100%" />
            <img src="/./assets/Images/QurtubaSum/IMG_7402.JPG" alt="This is an image" width="100%"  />
            <img src="/./assets/Images/QurtubaSum/IMG_7404.JPG" alt="unsplash" width="100%" height="397px"/>

                  </div>
                  </div> */}
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Events
