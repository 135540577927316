import React, { useState, useEffect, useRef } from 'react';
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Programs = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const testimContent = useRef([]);
  const testimDots = useRef([]);
  const testimSpeed = 4500;
  const testimTimer = useRef(null);

  const playSlide = (slide) => {
    if (slide < 0) {
      slide = testimContent.current.length - 1;
    }
  
    if (slide >= testimContent.current.length) {
      slide = 0;
    }
  
    if (testimContent.current[currentSlide]) {
      testimContent.current[currentSlide].classList.remove('active');
      testimContent.current[currentSlide].classList.add('inactive');
    }
  
    if (testimContent.current[slide]) {
      testimContent.current[slide].classList.remove('inactive');
      testimContent.current[slide].classList.add('active');
    }
  
    setCurrentSlide(slide);
  
    clearTimeout(testimTimer.current);
    testimTimer.current = setTimeout(() => {
      playSlide(slide + 1);
    }, testimSpeed);
  
    if (slide === testimContent.current.length - 1) {
      setTimeout(() => {
        playSlide(0);
      }, testimSpeed);
    }
  };
  
  
  

  const handleLeftArrowClick = () => {
    playSlide(currentSlide + 1); // Decrease slide index for left movement
  };
  
  const handleRightArrowClick = () => {
    playSlide(currentSlide - 1); // Increase slide index for right movement
  };

  const handleDotClick = (index) => {
    playSlide(index);
  };

  const handleKeyUp = (e) => {
    if (e.keyCode === 37) {
      handleLeftArrowClick();
    } else if (e.keyCode === 39) {
      handleRightArrowClick();
    }
  };

  const handleTouchStart = (e) => {
    touchStartPos.current = e.changedTouches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    touchEndPos.current = e.changedTouches[0].clientX;
    const touchPosDiff = touchStartPos.current - touchEndPos.current;

    if (touchPosDiff > 30) {
      handleLeftArrowClick();
    } else if (touchPosDiff < -30) {
      handleRightArrowClick();
    }
  };

  useEffect(() => {
    playSlide(currentSlide);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      clearTimeout(testimTimer.current);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [currentSlide]);

  const touchStartPos = useRef(0);
  const touchEndPos = useRef(0);

  return (
    <div>
    <Helmet>
      <title>برامج المكتبة</title>
    
    
    
    </Helmet>
    <Header />
   

<HeroImg title="برامج المكتبة"/>
    <main>
     
     
    <section id="testim" className="testim" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <div className="wrap">
        <span id="left-arrow" className="arrow left fa fa-chevron-left" onClick={handleLeftArrowClick}></span>
        <span id="right-arrow" className="arrow right fa fa-chevron-right" onClick={handleRightArrowClick}></span>

        <ul id="testim-dots" className="dots">
  {[...Array(8).keys()].map((index) => (
    <li
      key={index}
      className={`dot ${index === currentSlide ? 'active' : ''}`}
      ref={(el) => (testimDots.current[index] = el)}
      onClick={() => handleDotClick(index)}
    ></li>
  ))}
</ul>

        <div id="testim-content" className="cont">
          <div
            className={`testimonial ${currentSlide === 0 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[0] = el)}
          >
            <div className="img">
              <img src="/./assets/Images/library1.png" alt="" />
            </div>
            <div className="text-programs">

<h2>برنامج بوصلة</h2>

<p>
            <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        أحد برامج المكتبة الإثرائية التي تقدم عن بعد اتباعًا للإجراءات الاحترازية بسبب جائحة كورونا، حيث يصحبنا في رحلة عبر فضاءات القراءة الرحبة والتي يخفى علي الكثير مسالكها وأسرارها مع ضيوف مميزين ومدارات قرائية هامة لنفتح آفاقا نيرة لكل قارئ.

       <h6 style={{color:"#8b8888"}}>
        أهداف البرنامج:
        </h6>
        <ul>
            <li>
            1- رفع مستوى الوعي القرائي لدى الفئة المستفيدة والإجابة عن أسئلة مختلفة حول
القراءة.
            </li>
          

        </ul>
        <h6 style={{color:"#8b8888"}}>
       من مواضيع البرنامج:
        </h6>
        <ul>
            <li>
            1- أهمية القراءة ودورها في صناعة التغيير على المستوى الشخصي والأسري و المجتمعي.
            </li>
          <li>
            2- الوسائل المختلفة التي تصل بالقارئ لمرحلة النضج القرائي.
          </li>
          <li>
            3- الممارسات والاستراتيجيات التي تساعد على صناعة عادة القراءة.
          </li>
          <li>
            4- أساسيات القراءة الصحيحة وتقنياتها.
          </li>
          <li>
            5- استراتيجيات اختيار الكتب والتعامل مع المعلومات فيها.
          </li>
          <li>
            6- تقنيات وأدوات التدوين القرائي لتحقيق أعلى فائدة من القراءة.
          </li>
          <li>
            7- أهمية وطريقة إعداد الخرائط الذهنية لموضوع ما من خلال القراءة.
          </li>
          <li>
            8- مهارات التركيز في القراءة.
          </li>
          <li>
            9- آلیات استثمار المقروء.
          </li>
        </ul>
        </p>
     </div>
    
    </div>

          <div
            className={`testimonial ${currentSlide === 1 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[1] = el)}
          >
            <div className="img">
              <img src="/./assets/Images/logoQabas.png" alt="" />
            </div>
          <div className="text-programs">

      <h2>برنامج قبس</h2>
      <p>
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        أحد برامج المكتبة الحوارية التي تقدم عن بعد اتباعا للإجراءات الاحترازية بسبب جائحة كورونا، يحكي فيها ضيوفنا قصصا لشخصيات رائدة عرفها التاريخ مسلطين الضوء على إنسانيتها، فكرها النير، تعاملها الراقي مع ذاتها والآخرين وقيمها الثابتة التي لا تتزعزع.
       <br/> <br/>
       <h6 style={{color:"#8b8888"}}>
        أهداف البرنامج:
        </h6>
       
        <ul>
            <li>
            1- التأكيد على أهمية قراءة السير الذاتية للشخصيات الناجحة كونها أحد مصادر الإلهام لمن يريد النجاح والارتقاء في حياته.
            </li>
           

        </ul>
      </p>
    </div></div>

          <div
            className={`testimonial ${currentSlide === 2 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[2] = el)}
          >
            <div className="img">
              <img src="/./assets/Images/IMG_3684(2).PNG" alt="" />
            </div>
          <div className="text-programs">

      <h2>نادي قمارش</h2>
      <p >
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
       نادي قراءة افتراضي يقيم جميع الجلسات عبر برنامج (زوم) تناقش فيه كتب متعددة المجالات والاهتمامات يشرف عليه فريق نسائي تطوعي 100% تحت إشراف مسؤولة المكتبة.
       <br/> <br/>
       <h6 style={{color:"#8b8888"}}>
        أهداف البرنامج:
        </h6>
       
        <ul>
            <li>
            1- تعزيز مفهوم القراءة الجماعية وأثرها على ترسيخ أثر القراءة على القارئ.
            </li>
            <li>
            2- التأكيد على أهمية تفعيل مهارات التفكير خلال القراءة
            </li>

        </ul>
        <h5 style={{color:"#a5121d"}}>
       كتب المناقشة:
        </h5>
        <div class="flex flex-row box1 flex-wrap">
  <div class="p-1 ">
    <img class="bookview"src="https://i.imgur.com/jvihznu.jpeg"/>
  </div>
  <div class="p-1 ">
  <img class="bookview"src="https://i.imgur.com/4r8ru5Z.jpeg"/>
  </div>
  <div class="p-1 ">
  <img class="bookview"src="https://i.imgur.com/T0plkAQ.jpeg"/>
  </div>
  <div class="p-1 ">
    <img class="bookview"src="https://i.imgur.com/eOoqKNW.jpeg"/>
  </div>
  <div class="p-1 ">
    <img class="bookview"src="https://i.imgur.com/VBNc5an.jpeg"/>
  </div>
  <div class="p-1 ">
    <img class="bookview"src="https://i.imgur.com/gMx1ekz.jpeg"/>
  </div>
</div>
      </p>
    </div> </div>

          <div
            className={`testimonial ${currentSlide === 3 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[3] = el)}
          >
            <div className="img">
              <img src="https://i.imgur.com/NMfY8Pj.png" alt="" />
            </div>
            <div className="text-programs">

<h2>صيف قرطبة</h2>
<p >
<h6 style={{color:"#8b8888"}}>
وصف البرنامج:
</h6>

وجهة صيفية ثقافية لمدة شهر كامل ، متنوعة المسارات لترضي جميع الاهتمامات ( مسار أدبي ثقافي - مسار إعلامي - مسار فني ) ، تقدمها شخصيات متخصصة في مجالها 

<br/> <br/>

</p>
</div> </div>

          <div
            className={`testimonial ${currentSlide === 4 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[4] = el)}
          >
            <div className="img">
              <img src="https://i.imgur.com/uqmEwm9.jpeg" alt="" />
            </div>
          <div className="text-programs">

          <h2>مبادرة اعتماد</h2>
          <p >
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        مبادرة انطلقت امتدادا لليوم العالمي للكتاب ، تهدف إلى دعم المؤلفات السعوديات حيث نستضيف في كل لقاء مؤلفة سعودية في جلسة حوارية مع الجمهور متحدثة عن تجربتها مع التأليف لخلق مجتمع للمؤلفات يساهمن مع الجمعية في نشر ثقافة القراءة والتأليف ، ويختم اللقاء بحفل توقيع لكتب المؤلفة .       <br/> <br/>
      
      </p>
      </div>
      
      </div>
      <div
            className={`testimonial ${currentSlide === 5 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[5] = el)}
          >
            <div className="img">
              <img src="https://i.imgur.com/67yjJIU.jpeg" alt="" />
            </div>
          <div className="text-programs">

          <h2>برنامج بانوراما</h2>
          <p >
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        تفعيلاً لمناسبة ( اليوم الوطني ) و بشراكة فاعلة مع قسم كلية التصاميم بجامعة الإمام عبد الرحمن بن فيصل ، أقيمت سلسلة من اللقاءات الإثرائية الحوارية قدمتها نخبة من المتخصصات و الأكاديميات في الجامعة  ، بهدف تعزيز الهوية الوطنية من خلال تسليط الضوء على أبرز ملامحها الثقافية .       <br/> <br/>
       <h6 style={{color:"#8b8888"}}>
        من مواضيع البرنامج:
        </h6>
       
        <ul>
            <li>
            1- الأزياء النسائية  التقليدية في المملكة.
            </li>
            <li>
            2- العمارة التراثية  في منطقة نجد  وتفرد الهوية السعودية.
            </li>
            <li>
            3- المأكولات السعودية تراث وهوية.
            </li>
            <li>
            4- الحرف التقليدية التراثية في السعودية.
            </li>

        </ul>
      </p>
      </div>
      
      </div>
      <div
            className={`testimonial ${currentSlide === 6 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[6] = el)}
          >
            <div className="img">
              <img src="https://i.imgur.com/agfN9FW.jpeg" alt="" />
            </div>
          <div className="text-programs">

          <h2>برنامج صبح</h2>
          <p >
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        لقاءات صباحية  حوارية اثرائية تقام  أول سبت من كل شهر  ،  نناقش فيها مواضيع في مجالات متنوعة مثل " التربية - تطوير الذات – العلاقات الإنسانية وغيرها" كما يطرح فيها اقتراحات العناوين  كتب إثرائية عن موضوع اللقاء ، نستضيف فيه شخصيات رائدة في مجالها .
      
      </p>
      </div>
      
      </div>
      <div
            className={`testimonial ${currentSlide === 7 ? 'active' : 'inactive'}`}
            ref={(el) => (testimContent.current[7] = el)}
          >
            <div className="img">
              <img src="https://i.imgur.com/wK2qiSs.jpeg" alt="" />
            </div>
          <div className="text-programs">

          <h2>برنامج ليوان</h2>
          <p >
      <h6 style={{color:"#8b8888"}}>
        وصف البرنامج:
        </h6>
        تفعيلاً لعام الشعر العربي أقيمت سلسة من  اللقاءات الحوارية الشعرية حول الشعر وعصور الأدب العربي ، تخللها أمسيات شعرية قصيرة .      
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
      </p>
      </div>
      
      </div>
        
        </div>
      </div>
    </section>
   
    </main>
    <Footer />
    </div>
  );
};

export default Programs;
