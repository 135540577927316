// Card.js
import React from 'react';
import { Link } from 'react-router-dom';

export const Card = ({ item }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
  <div className="news">
      {Array.isArray(item) && item.map((item) => (
       <div key={item.id} className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
      
         <div
           className="card-body gallery-img"
           style={{ backgroundImage: `url(${item.img})`, width: "300px" }}
         ></div>
         <div className="card-footer text-left">
           <p className="card-text name-card xsmall text-right" style={{ minHeight: "2lh" }}>
             {item.title}
           </p>
           <p className="card-text s16 detail-card xsmall"style={{color:"#79121F"}} >
           <Link to={`/prog/${encodeURIComponent(item.name)}`} style={{color:"#79121F"}}>
             المزيد<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
             </Link>
           </p>
         </div>
      
     </div>
      ))}
      {/* If item is not an array, render a single card */}
      {!Array.isArray(item) && (
        <div key={item.id} className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
       
        <div
      className="card-body gallery-img"
      style={{ 
        backgroundImage: `url(${item.img})`, 
        backgroundPosition: 'center', // Center the background image
        backgroundSize: 'cover', // Maintain aspect ratio
        width: "100%", // Set width to 100% to fill the container
        height: "200px", // Set height to maintain aspect ratio
        display: "flex", // Make the div a flex container
        alignItems: "center", // Center vertically
        justifyContent: "center", // Center horizontally
        borderTopLeftRadius: "10px", // Apply border radius to upper-left corner
        borderTopRightRadius: "10px" // Apply border radius to upper-right corner
      }}
    ></div>
          <div className="card-footer text-left">
            <p className="card-text name-card xsmall text-right" style={{ minHeight: "2lh" }}>
              {item.title}
            </p>
            <p className="card-text s16 detail-card xsmall"style={{color:"#79121F"}} >
            <Link to={`/prog/${encodeURIComponent(item.name)}`} style={{color:"#79121F"}}>
             المزيد<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
             </Link>
             </p>
          </div>
        
      </div>
      )}
    </div>
    </div>
  );
};



// export const Card = ({ item }) => { 
//   <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
//   <div className="news">
//   {Array.isArray(item) && item.map((item) => (
//   <div key={item.id} className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
//                 <a href="#">
//                   <div
//                     className="card-body gallery-img"
//                     style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
//                   ></div>
//                   <div className="card-footer text-left">
//                     <p className="card-text name-card xsmall text-right" style={{ minHeight: "2lh" }}>
//                       {item.title}
//                     </p>
//                     <p className="card-text s16 detail-card xsmall">
//                       عرض<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
//                     </p>
//                   </div>
//                 </a>
//               </div>

// ))}
//   {!Array.isArray(item) && (
//     <div key={item.id} className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
//     <a href="#">
//       <div
//         className="card-body gallery-img"
//         style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
//       ></div>
//       <div className="card-footer text-left">
//         <p className="card-text name-card xsmall text-right" style={{ minHeight: "2lh" }}>
//           {item.title}
//         </p>
//         <p className="card-text s16 detail-card xsmall">
//           عرض<i className="fa fa-long-arrow-left" aria-hidden="true"></i>
//         </p>
//       </div>
//     </a>
//   </div>
//  )}
//               </div>
//               </div>
// }