import React, { Component,useState } from "react"
import Header from "../Header2"
import Footer from "../Footer"
import { Helmet } from "react-helmet"
import HeroImg from "../HeroImg"
import "./gallery.css";

const Andlus = () => {
  const images = [
    { id: 1, category: "الموسم الأول", src: "https://i.imgur.com/Jgb54ic.jpeg" },
    { id: 2, category: "الموسم الأول", src: "https://i.imgur.com/ufpkj6D.jpeg" },
    { id: 3, category: "الموسم الأول", src: "https://i.imgur.com/NVLL7pk.jpeg" },
    { id: 4, category: "الموسم الأول", src: "https://i.imgur.com/ZQRSSN5.jpeg" },
    { id: 5, category: "الموسم الأول", src: "https://i.imgur.com/SI49YP6.jpeg" },
    { id: 6, category: "الموسم الأول", src: "https://i.imgur.com/sEuDoX5.jpeg" },

    { id: 7, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/S1tXc0t.jpeg" },
    { id: 8, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/yqM0g48.jpeg" },
    { id: 9, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/y2zqndm.jpeg" },
    { id: 10, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/BsIeg47.jpeg" },
    { id: 11, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/kq8GKMD.jpeg" },
    { id: 12, category: "الموسم الثاني- اللقاءات", src: "https://i.imgur.com/SguQ2bz.jpeg" },

    { id: 13, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/1h2UNyq.jpeg" },
    { id: 14, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/ZA1yE0O.jpeg" },
    { id: 15, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/26G5jiR.jpeg" },
    { id: 16, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/2Kg4Q2u.jpeg" },
    { id: 17, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/BYUVn05.jpeg" },
    { id: 18, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/oyMyynY.jpeg" },
    { id: 19, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/9NcRACa.jpeg" },
    { id: 20, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/CbYb7N1.jpeg" },
    { id: 21, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/bT60kqT.jpeg" },
    { id: 22, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/557OSFK.jpeg" },
    { id: 23, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/xzyTsgn.jpeg" },
    { id: 24, category: "الموسم الثاني- الحفل الختامي", src: "https://i.imgur.com/vWnkDDV.jpeg" },
    
  ];
  
  const categories = [ "الموسم الأول", "الموسم الثاني- اللقاءات", "الموسم الثاني- الحفل الختامي"];
  const [filter, setFilter] = useState("الموسم الأول");

  const handleFilterChange = (category) => {
    setFilter(category);
  };

 
  return (
    <div>
    <Helmet>
      <title>برنامج دار أندلسية</title>
    
    
    
    </Helmet>
    <Header />
    
    <body >

<HeroImg title="برنامج دار أندلسية" />
    <main>
    <div className="desktop_7">
      <div className="responsive-container-block bigContainer">
        <div className="responsive-container-block Container">
          <div className="responsive-container-block optionsContainer">
          {categories.map((category) => (
              <p
                key={category}
                className={`text-blk list ${filter === category ? "active" : ""}`}
                onClick={() => handleFilterChange(category)}
                data-filter={category}
              >
                {category}
              </p>
            ))}
          </div>
          <div className="responsive-container-block imageContainer">
            {images
              .filter((image) => filter === "all" || image.category === filter)
              .map((image) => (
                <div className="project" key={image.id}>
                  <img className={`squareImg ${image.category}`} src={image.src} alt={image.category} />
                
                 
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
    {/* <div class="album">
  <div class="responsive-container-block bg">
    <div class="responsive-container-block img-cont">
      <img class="img" src="/./assets/Images/Andlus/daa31f66-f569-441b-8259-e09365a0bc10.jpg"/>
      <img class="img" src="/./assets/Images/Andlus/Snapseed.jpg"/>
      <img class="img img-last" src="/./assets/Images/Andlus/IMG_0556.jpg"/>
    </div>
    <div class="responsive-container-block img-cont">
    <img class="img img-big" src="/./assets/Images/Andlus/e5f0a969-1b4b-44e1-95d9-dda4a43bc572.jpg"/>

      <img class="img img-big" src="/./assets/Images/Andlus/e5f0a969-1b4b-44e1-95d9-dda4a43bc572.jpg"/>
      <img class="img img-big img-last" src="/./assets/Images/Andlus/8b4c669a-d0b0-4e65-854d-81c3be23fbe1.jpg"/>
    </div>
    <div class="responsive-container-block img-cont">
      <img class="img" src="/./assets/Images/Andlus/1221da09-7374-4ad5-8dc8-b2357125f006.jpg"/>

      <img class="img" src="/./assets/Images/Andlus/720d5871-44fd-4e56-9b92-5a8af934bde1.jpg"/>
      <img class="img" src="/./assets/Images/Andlus/4ac7145f-9fd8-41ae-899f-32d5273f3c98.jpg"/>
    </div>
  </div>
</div> */}

{/* <div class="main-container">
      <div class="container-gallery">
        <img src="/./assets/Images/Andlus/4ac7145f-9fd8-41ae-899f-32d5273f3c98.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/Andlus/720d5871-44fd-4e56-9b92-5a8af934bde1.jpg" alt="This is an image" width="100%" height="auto"/>
        <img src="/./assets/Images/Andlus/1221da09-7374-4ad5-8dc8-b2357125f006.jpg" alt="unsplash" width="100%" height="auto"/>
        <img src="/./assets/Images/Andlus/8b4c669a-d0b0-4e65-854d-81c3be23fbe1.jpg" alt="unsplash" width="100%" height="335"/>
        </div>
        <div class="container-gallery">
          <img src="/./assets/Images/Andlus/IMG_0537.jpg" alt="This is an image" width="100%" />
          <img src="/./assets/Images/Andlus/e5f0a969-1b4b-44e1-95d9-dda4a43bc572.jpg" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/Andlus/IMG_0556.jpg" alt="This is an image" width="100%" />
          <img src="/./assets/Images/Andlus/Snapseed.jpg" alt="This is an image" width="100%" height="350" />

          </div>
          <div class="container-gallery">
            <img src="/./assets/Images/Andlus/IMG_0988.jpg" alt="This is an image" width="100%" height="250"/>
            <img src="/./assets/Images/Andlus/daa31f66-f569-441b-8259-e09365a0bc10.jpg" alt="This is an image" width="100%" height="auto"/>
              <img src="/./assets/Images/Andlus/CA75ED73-4ED2-445F-95AB-07D32C002257.JPG" alt="This is an image" width="100%" />
                <img src="/./assets/Images/Andlus/phonto(1).jpg" alt="unsplash" width="100%" height="318"/>
                  </div>
                  </div> */}

{/* <div class="row1"> 
  <div class="column1">
    <img src="/./assets/Images/Andlus/4ac7145f-9fd8-41ae-899f-32d5273f3c98.jpg" style={{width:"100%"}} />
    <img src="/./assets/Images/Andlus/720d5871-44fd-4e56-9b92-5a8af934bde1.jpg" style={{width:"100%"}}/>
    <img ssrc="/./assets/Images/Andlus/1221da09-7374-4ad5-8dc8-b2357125f006.jpg" style={{width:"100%"}}/>
    <img src="/./assets/Images/Andlus/8b4c669a-d0b0-4e65-854d-81c3be23fbe1.jpg" style={{width:"100%"}}/>
    
  </div>
  <div class="column1">
  <img src="/./assets/Images/Andlus/IMG_0537.jpg" alt="This is an image" width="100%" />
          <img src="/./assets/Images/Andlus/e5f0a969-1b4b-44e1-95d9-dda4a43bc572.jpg" alt="This is an image" width="100%" height="auto"/>
          <img src="/./assets/Images/Andlus/IMG_0556.jpg" alt="This is an image" width="100%" />
          <img src="/./assets/Images/Andlus/Snapseed.jpg" alt="This is an image" width="100%" height="350" />

  </div>  
  
  <div class="column1">
  <img src="/./assets/Images/Andlus/IMG_0988.jpg" alt="This is an image" width="100%" height="250"/>
            <img src="/./assets/Images/Andlus/daa31f66-f569-441b-8259-e09365a0bc10.jpg" alt="This is an image" width="100%" height="auto"/>
              <img src="/./assets/Images/Andlus/CA75ED73-4ED2-445F-95AB-07D32C002257.JPG" alt="This is an image" width="100%" />
                <img src="/./assets/Images/Andlus/phonto(1).jpg" alt="unsplash" width="100%" height="318"/>
                
  </div>
</div> */}
      <br/><br/>
    </main>
    <Footer />
    </body>
    </div>

  )
}
export default Andlus
