import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Objectives = () => {
  return (
    <div>
      <Helmet>
        <title>عن قرطبة</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="عن قرطبة" />
        <main>
          {/* <div className="hero-container">
    <br/>
    <h1  align="center" style={{color: "#AA2630"}}>أهدافنا</h1>
  </div> */}
          <div class="container x" style={{ direction: "rtl" }}>
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <div class="Title">
                    <b>الرؤية:</b>
                  </div>
                  <div class="Points">الخيار الأول في بناء مجتمع قارئ ومثقف.</div>
                </p>
              </div>
            </div>

          
            <div class="emp_card well">
              <div>
                <p class="newsPar">
                  <div class="Title">
                    <b>الأهداف:</b>
                  </div>
                  <div class="Points">
                    <ul class="a">
                      <li style={{ listStyleType: "circle" }}>
                        نشر ثقافة القراءة الهادفة.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تكوين نماذج مثقفة معتزة بقيمها وهويتها.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        زيادة إيرادات الجمعية من مصادر متنوعة وشبه ثابتة.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تحسين الكفاءة المالية وترشيد الإنفاق.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تصميم برامج وبيئات نوعية ومبتكرة وجاذبة للفتيات.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        بناء الشراكات التي تخدم أهداف الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تسويق برامج وأنشطة الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        استثمار التحول الرقمي وأتمتة عمليات الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        استثمار المتطوعين في أعمال الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        تأهيل وتدريب العاملين في الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>
                        بناء أنظمة ولوائح الجمعية.
                        <br />
                      </li>
                      <li style={{ listStyleType: "circle" }}>بناء بيئة عمل جاذبة.</li>
                    </ul>
                  </div>
                </p>
              </div>
            </div>
            <br />
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Objectives
