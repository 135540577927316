import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Changemaking = () => {
  return (
    <div>
      <Helmet>
        <title> قرطبة وصناعة التغيير</title>

      </Helmet>
      <Header />

      <body>
        <HeroImg title="قرطبة وصناعة التغيير" />
        <main>
          <div class="container" >
          <br/>
            <div style={{marginLeft:"8rem",marginRight:"8rem"}}>
          <p style={{textAlign:"center"}}>
              من احتمالات الأيام ومساحاتها الشاسعة
وهل من الضرورة تكون الأبجديات مرتبة؟
<br/>
في حكاية <span style={{color:"#79121f"}}>وجدان الورثان</span>  قد تتسابق الحروف ويفوز أحدهما على الآخر
فصولي لم تبدأ بحرف الألف، بل كان سؤال ملحاً
<br/>
ما هو الكتاب الذي يجعلني لا أتوقف عن قراءته؟
<br/>
وما معنى أن تكون قارئ؟
<br/>
إنه عنوان صغير ثم ُتسرد القصة وأبجدية مختلفة.

              </p>
              <br/>
              <div class="container">
                <div
                  class="panel-group"
                  id="accordion"
                  role="tablist"
                  aria-multiselectable="true"
                  style={{ direction: "rtl" }}
                >
                  <div class="panel panel-default" style={{width:"600px"}}>
                    <div class="panel-heading" role="tab" id="headingOne">
                      <h4 class="panel-title">
                        <a
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                         قاف
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseOne"
                      class="panel-collapse collapse in"
                      role="tabpanel"
                      aria-labelledby="headingOne"
                    >
                      <div class="panel-body" style={{textAlign:"center"}}>
                  
                   نعم قد تبدأ الحكاية بحرف القاف "قرطبة" 
                   <br/>
وفي رحابة جمعية قرطبة تحديًدا برنامج دار أندلسية حيث البدايات
<br/>
وإشراقة الوجهة نحو المعرفة وسمو الأهداف
<br/>
من هنا التقيت بِ " اقرأ " بمعناها الحقيقي أو ربما تعرفت عليها من جديد
<br/>
عرفتني على صحب الكتب وبدأت معهم رحلة لا تشبهها أي رحلة
<br/>
وهنا "اقرأ " كانت مطلب وحاجة وقرار بالعيش معها إلى الأبد
                      
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default" style={{width:"600px"}}>
                    <div class="panel-heading" role="tab" id="headingTwo">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          عين
                        </a>
                      </h4>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                      <div class="panel-body" style={{textAlign:"center"}}>
                      عين النظرة الأولى للصفحات، عين الحب للسطور
<br/>
عين الانتباه للكلمة وعين الشعور لما وراءها
<br/>
عيناي لم تعد عينان وهذا الأثر الوافر للقراءة    
<br/>
بين جغرافيا العقول والتنقل بين الثقافات
<br/>
سحرتني القراءة جعلتني اتحدث دون توقف ولا أريد الشفاء من سحرها..!

                       
                      </div>
                    </div>
                  </div>

                  <div class="panel panel-default" style={{width:"600px"}}>
                    <div class="panel-heading" role="tab" id="headingThree">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                         ميم
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseThree"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingThree"
                    >
                      <div class="panel-body" style={{textAlign:"center"}}>
                      دفء الميم في مجتمع يدفعني للتأثير فيه
<br/>
والعمل المجتمعي الذي أضاف فصلاً حيويًا
<br/>
غني بالقراء والأصدقاء، لقاء تلو مناقشة
<br/>
حتى تصدرت الكلام وتحولت إلى فعل التأثير
<br/>            
أن تقرأ فأنت لست وحدك
<br/>
ألا تقف على المعاني بعقل واحد
<br/>
أن تكون أنت الضوء الساطع من ميم المعرفة.

                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default" style={{width:"600px"}}>
                    <div class="panel-heading" role="tab" id="headingFour">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                         سين
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseFour"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingFour"
                    >
                      <div class="panel-body" style={{textAlign:"center"}}>
                      " هناك ثمة سحر يكمن في أن تكون محاًطا بالكتب "
<br/>
بل إنها عملية جذب للتغيير، والسفر لدور الكتب والترحال للمعارض،
<br/>
واللقاء بعالقات عديدة ممتدة بعالم يجعلك تنمو تزهر وتثمر
<br/>
حتى تصبح ظلًا للآخر كالأشجار.

                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default" style={{width:"600px"}}>
                    <div class="panel-heading" role="tab" id="headingFive">
                      <h4 class="panel-title">
                        <a
                          class="collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseFive"
                          aria-expanded="false"
                          aria-controls="collapseFive"
                        >
                         كاف
                        </a>
                      </h4>
                    </div>
                    <div
                      id="collapseFive"
                      class="panel-collapse collapse"
                      role="tabpanel"
                      aria-labelledby="headingFive"
                    >
                      <div class="panel-body" style={{textAlign:"center"}}>
                      كتاب تبحث فيه عن قصتك لا تجدها
<br/>
كتاب تنصت له يرسم لك خريطة تتهدي لها في ظالمك
<br/>
كتاب تحتاج أن تشاركه مع صديق لتكتمل نهايته
<br/>
الكاف هنا هي كاف في فعل اكمل فللكتب بقية لا تنتهي ..
<br/>
وحب يريك وجهًا آخر من الدنيا تراه بين دفتي كتاب
<br/>
هل تعتقد بأن القارئ يقف؟


                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </div>
              <br/>  <br/>
            <section>
            
             

              <div style={{marginLeft:"8rem",marginRight:"8rem"}}>
          <p style={{textAlign:"center"}}>
          انتهت حكاية وجدان
<br/>
      <span style={{color:"#79121f",textDecorationLine:"underline"}}>كُن أنت القصة التالية</span>  

              </p>
              <br/>
              </div>
            </section>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Changemaking
