import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>قصتنا</title>
        <script type="text/javascript" src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"></script>
        <script
          type="text/javascript"
          src=" https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.4.2/js/swiper.jquery.min.js"
        ></script>
        <script type="text/javascript" src="/./assets/js/about.js"></script>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="قصتنا" />
        <main>
          <div class="timeline justify-content-center">
            <div class="timeline__component align-self-center">
              <div class="timeline__date timeline__date--right">البداية</div>
            </div>
            <div class="timeline__middle">
              <div class="timeline__point"></div>
            </div>
            <div class="timeline__component timeline__component--bg">
              <img src="https://i.imgur.com/yHhHLMd.jpg" />
              <p class="timeline__paragraph">
                جمعتنا كلمة .. فبِها عُرفنا ، و على ضفافها التقينا .. تعاهدنا ميثاقها بحب ، فكان انبثاق الفجر مؤذِنا
                بأصباحَ مورقة .. كانت الومضة الأولى حين لمعت الفُكيرة في نفوس محبي القراءة ، ثم توالت اللقاءات الجامعة
                لهنّ لتلتقي الأفكار و الأهداف . و قد آثَر أهل الشغف بثّ هذا الشغف في سماء المنطقة الشرقية ، فاتخذن من
                مدينة" الدمام" فنارًا هاديا لكل متطلعة و هاوية للقراءة ، و قُرر ذلك بعد سَبر جمعيات المنطقة و أنشطتها
              </p>
            </div>
            <div class="timeline__component timeline__component--bg">
              <img src="assets/Images/Q6.jpg" />

              <p class="timeline__paragraph">
                فكانت "جمعية قرطبة للقراءة "هي الفجر المرتقَب و النمير العذب للواردين وقد طال الانتظار و استطالت الآمال
                حتى تأسست الجمعية بفضل الله بعد موافقة وزارة العمل و التنمية الاجتماعية بتاريخ : 2018/5/26 لتكون الرائدة
                بين الجمعيات في تخصصها بنشر ثقافة القراءة، وتوجيه نفعها للفتيات بين الأعمار : من 6 سنوات و حتى 35 عاما .
              </p>
            </div>
            <div class="timeline__middle">
              <div class="timeline__point"></div>
            </div>
            <div class="timeline__component align-self-center">
              <div class="timeline__date">نقطة الإنطلاق</div>
            </div>
            <div class="timeline__component align-self-center">
              <div class="timeline__date timeline__date--right">سعي مستمر</div>
            </div>
            <div class="timeline__middle">
              <div class="timeline__point"></div>
            </div>
            <div class="timeline__component align-self-center timeline__component--bg">
              <img src="assets/Images/Q7.jpg" />

              <p class="timeline__paragraph">
                ثم تشكّل مجلس الإدارة من الأعضاء السبعة بقيادة الدكتورة الكريمة : هدى الملحم.. و بدأت الانطلاقة و السعي
                الحثيث لتوفير مكان وبرنامج ملائم يقدّم خدماته للمتطلعات. و في كل هذه المنعطفات فإنه خليق بنا ألا ننسى
                أهل الأيادي البيضاء " أفرادا و مؤسساتٍ" الداعمين لنشاط الجمعية و الراعين أهدافها و طموحها ،
              </p>
            </div>
            <div class="timeline__component align-self-center timeline__component--bottom timeline__component--bg">
              <img src="assets/Images/Q9.jpg" />

              <p class="timeline__paragraph">
                و لا ننسى كذلك الكريمات المتطوعات اللواتي بذلن أوقاتهن و أفكارهن و جهودهن على طاولة البحث في قرطبة ؛
                لتكون قرطبة الوجهة الفريدة و الخيار الأول لكل من تحب المطالعة ، و حسب قرطبة شرفاً أن تبعث كوامن القراءة
                من غور النفوس و تجعلها عالما ماثلا بين العيون تلامس نجومه الأيادي .. و الله يُجزل الأجر و يرفع القدْر
                لكل من وجّه و سدّد و عبر و قرأ ..
              </p>
            </div>
            <div class="timeline__middle">
              <div class="timeline__point"></div>
            </div>
            <div class="timeline__component align-self-center timeline__component--bottom">
              <div class="timeline__date">
                أيادي عطاء..
                <br /> والرحلة تستمر
              </div>
            </div>
          </div>

          <br />
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default AboutUs
