import React, { useState, useMemo } from "react"
import Header2 from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"

const Podcast = () => {
  return (
    <div>
      <Helmet>
        <title>بودكاست إطلالة</title>
      </Helmet>
      <Header2 />

      {/* <div class="wrapper1">
  <div class="container">
  <img class="play" src="https://i.imgur.com/Vidao4q.png" />

  <div class="centered">
    <h1  style={{color:"white"}}>الصوتيات</h1> </div>
</div>
<img src="https://i.imgur.com/ONhe5wQ.png" />

</div> */}
      <body>
        <HeroImg title="الصوتيات" />

        <main>
          <div className="row justify-content-center">
            <div className="col-md-8 mx-5 ">
              <div class="card m-4">
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-4 text-center align-self-center">
                          {" "}
                          <img src="https://i.imgur.com/cP8WANX.png" className="img-fluid " />
                        </div>
                        <div className="col-md-8 text-right align-self-center">
                          <h6 className="med-51" style={{ margin: "10px" }}>
                        بودكاست إطلالة   -  الموسم الأول
                          </h6>
                          <p className="small1">
                            قنطرتك الممتدة .. طريقك المختصر ..بابكَ القريب لعبور عالم القراءة .."بودكاست إطلالة "
                          </p>
                          <hr />
                          <div className="row flex-sm-row text-center">
                            <div className="col-md-4">
                              <h3 className="small1"> الحلقات</h3>
                              <h6 className="small1">٢٠</h6>
                            </div>
                           
                            <div className="col-md-4">
                              <h3 className="small1">المتابعين </h3>
                              <h6 className="small1">٨٨٢</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <a href="https://www.youtube.com/watch?v=ncuW1VQA-S0&list=PLP8RYlWyY52_1_5jb7_SOE1z_VzOVFBa0&pp=iAQB" target="_blank" className="text-decoration-none">
                        <div className="row justify-content-end">
                          <div className="d-flex flex-row  justify-content-end ml-3" style={{ width: "550px" }}>
                            <div
                              class="d-flex flex-row align-items-center transBor"
                              style={{
                                width: "9.06rem",
                                height: "2.70rem",
                                textAlign: "left",
                                borderRadius: "14px",
                                paddingRight: "0.5rem",
                              }}
                            >
                              <div style={{ height: "30px", width: "30px" }}>
                                <img src="https://i.imgur.com/cYD8vIt.png" width={40} height={30} />
                              </div>
                              <div class="d-flex flex-column">
                                <h6 style={{ textAlign: "right", fontSize: "0.6rem", paddingRight: "0.6rem" }}>
                                  استمع على
                                </h6>
                                <h6
                                  style={{
                                    fontSize: "0.8rem",
                                    paddingRight: "0.7rem",
                                    color: "grey",
                                    fontWeight: "bold",
                                  }}
                                >
                                 YOUTUBE
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 mx-5 ">
              <div class="card m-4">
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-4 text-center align-self-center">
                          {" "}
                          <img src="https://i.imgur.com/cP8WANX.png" className="img-fluid " />
                        </div>
                        <div className="col-md-8 text-right align-self-center">
                          <h6 className="med-51" style={{ margin: "10px" }}>
                        بودكاست إطلالة   -  الموسم الثاني
                          </h6>
                          <p className="small1">
                            قنطرتك الممتدة .. طريقك المختصر ..بابكَ القريب لعبور عالم القراءة .."بودكاست إطلالة "
                          </p>
                          <hr />
                          <div className="row flex-sm-row text-center">
                            <div className="col-md-4">
                              <h3 className="small1"> الحلقات</h3>
                              <h6 className="small1">١٤</h6>
                            </div>
                           
                            <div className="col-md-4">
                              <h3 className="small1">المتابعين </h3>
                              <h6 className="small1">٨٨٢</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 align-self-center">
                      <a href="https://www.youtube.com/watch?v=96nVOki2Ow0&list=PLP8RYlWyY52_ZSY475UH7PCPBA8tqJVtY" target="_blank" className="text-decoration-none">
                        <div className="row justify-content-end">
                          <div className="d-flex flex-row  justify-content-end ml-3" style={{ width: "550px" }}>
                            <div
                              class="d-flex flex-row align-items-center transBor"
                              style={{
                                width: "9.06rem",
                                height: "2.70rem",
                                textAlign: "left",
                                borderRadius: "14px",
                                paddingRight: "0.5rem",
                              }}
                            >
                              <div style={{ height: "30px", width: "30px" }}>
                                <img src="https://i.imgur.com/cYD8vIt.png" width={40} height={30} />
                              </div>
                              <div class="d-flex flex-column">
                                <h6 style={{ textAlign: "right", fontSize: "0.6rem", paddingRight: "0.6rem" }}>
                                  استمع على
                                </h6>
                                <h6
                                  style={{
                                    fontSize: "0.8rem",
                                    paddingRight: "0.7rem",
                                    color: "grey",
                                    fontWeight: "bold",
                                  }}
                                >
                                 YOUTUBE
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div class=" d-flex flex-column align-items-center" style={{backgroundColor:"#F3F2F1"}}>
 
  <div class="card d-flex customize">
      <div class="card-body">
        <div className='d-flex flex-row'>

<div className='d-flex justity-content-xl-start align-items-center align-items-xll-center' style={{textAlign:"right",width:"1000px"}}>
<img src="https://i.imgur.com/cP8WANX.png" class="card-img-top"/>
<h6 style={{margin:"10px"}}>
بودكاست إطلالة
</h6>
</div>
<div className='d-flex flex-row align-items-center' style= {{width: "1000px",textAlign:"center"}}>
<div style={{width:"130px"}}>
  <h6>عدد الحلقات</h6>
  <h6>10</h6>
</div>
<div style={{width:"130px"}}>
  <h6>عدد المواسم</h6>
  <h6>3 </h6>
</div>
<div style={{width:"130px"}}>
  <h6> آخر تحديث</h6>
  <h6>10-3-1444</h6>
</div>
</div>
</div>

<div className="d-flex flex-row  justify-content-end" style={{width: "550px"}}>
<div class="d-flex flex-row align-items-center transBor"style={{width: "9.06rem",height: "2.70rem",textAlign: "left",borderRadius: "14px",paddingRight:"0.5rem"}}>
<div style={{height:"30px",width:"30px"}}>
<img src="https://i.imgur.com/kvFWrbg.png" width={30} height={30}/>

</div>
<div class="d-flex flex-column">
<h6 style={{textAlign:"right",fontSize:"0.6rem",paddingRight:"0.3rem"}}>
استمع على
</h6>
<h6 style={{fontSize:"0.8rem",paddingRight:"0.1rem",color:"#FF4401",fontWeight:"bold"}}>
    SOUNDCLOUD
  </h6>
</div>

</div>

</div>


  </div>
  </div>
  <div class="card d-flex customize">
  <div class="card-body">
    <div className='d-flex flex-row'>

<div className='d-flex justity-content-xl-start align-items-center align-items-xll-center' style={{textAlign:"right",width:"1000px"}}>
<img src="https://i.imgur.com/cP8WANX.png" class="card-img-top"/>
<h6 style={{margin:"10px"}}>
بودكاست إطلالة
</h6>
</div>
<div className='d-flex flex-row align-items-center' style= {{width: "1000px",textAlign:"center"}}>
<div style={{width:"130px"}}>
  <h6>عدد الحلقات</h6>
  <h6>10</h6>
</div>
<div style={{width:"130px"}}>
  <h6>عدد المواسم</h6>
  <h6>3 </h6>
</div>
<div style={{width:"130px"}}>
  <h6> آخر تحديث</h6>
  <h6>10-3-1444</h6>
</div>
</div>
</div>

<div className="d-flex flex-row  justify-content-end" style={{width: "550px"}}>
<div class="d-flex flex-row align-items-center transBor"style={{width: "9.06rem",height: "2.70rem",textAlign: "left",borderRadius: "14px",paddingRight:"0.5rem"}}>
<div style={{height:"30px",width:"30px"}}>
<img src="https://i.imgur.com/kvFWrbg.png" width={30} height={30}/>

</div>
<div class="d-flex flex-column">
<h6 style={{textAlign:"right",fontSize:"0.6rem",paddingRight:"0.3rem"}}>
استمع على
</h6>
<h6 style={{fontSize:"0.8rem",paddingRight:"0.1rem",color:"#FF4401",fontWeight:"bold"}}>
    SOUNDCLOUD
  </h6>
</div>

</div>

</div>


  </div>
  </div>
  <div class="card d-flex customize">
  <div class="card-body">
    <div className='d-flex flex-row'>

<div className='d-flex justity-content-xl-start align-items-center align-items-xll-center' style={{textAlign:"right",width:"1000px"}}>
<img src="https://i.imgur.com/cP8WANX.png" class="card-img-top"/>
<h6 style={{margin:"10px"}}>
بودكاست إطلالة
</h6>
</div>
<div className='d-flex flex-row align-items-center' style= {{width: "1000px",textAlign:"center"}}>
<div style={{width:"130px"}}>
  <h6>عدد الحلقات</h6>
  <h6>10</h6>
</div>
<div style={{width:"130px"}}>
  <h6>عدد المواسم</h6>
  <h6>3 </h6>
</div>
<div style={{width:"130px"}}>
  <h6> آخر تحديث</h6>
  <h6>10-3-1444</h6>
</div>
</div>
</div>

<div className="d-flex flex-row  justify-content-end" style={{width: "550px"}}>
<div class="d-flex flex-row align-items-center transBor"style={{width: "9.06rem",height: "2.70rem",textAlign: "left",borderRadius: "14px",paddingRight:"0.5rem"}}>
<div style={{height:"30px",width:"30px"}}>
<img src="https://i.imgur.com/kvFWrbg.png" width={30} height={30}/>

</div>
<div class="d-flex flex-column">
<h6 style={{textAlign:"right",fontSize:"0.6rem",paddingRight:"0.3rem"}}>
استمع على
</h6>
<h6 style={{fontSize:"0.8rem",paddingRight:"0.1rem",color:"#FF4401",fontWeight:"bold"}}>
    SOUNDCLOUD
  </h6>
</div>

</div>

</div>


  </div>
  </div>
  <div class="card d-flex customize">
  <div class="card-body">
    <div className='d-flex flex-row'>

<div className='d-flex justity-content-xl-start align-items-center align-items-xll-center' style={{textAlign:"right",width:"1000px"}}>
<img src="https://i.imgur.com/cP8WANX.png" class="card-img-top"/>
<h6 style={{margin:"10px"}}>
بودكاست إطلالة
</h6>
</div>
<div className='d-flex flex-row align-items-center' style= {{width: "1000px",textAlign:"center"}}>
<div style={{width:"130px"}}>
  <h6>عدد الحلقات</h6>
  <h6>10</h6>
</div>
<div style={{width:"130px"}}>
  <h6>عدد المواسم</h6>
  <h6>3 </h6>
</div>
<div style={{width:"130px"}}>
  <h6> آخر تحديث</h6>
  <h6>10-3-1444</h6>
</div>
</div>
</div>

<div className="d-flex flex-row  justify-content-end" style={{width: "550px"}}>
<div class="d-flex flex-row align-items-center transBor"style={{width: "9.06rem",height: "2.70rem",textAlign: "left",borderRadius: "14px",paddingRight:"0.5rem"}}>
<div style={{height:"30px",width:"30px"}}>
<img src="https://i.imgur.com/kvFWrbg.png" width={30} height={30}/>

</div>
<div class="d-flex flex-column">
<h6 style={{textAlign:"right",fontSize:"0.6rem",paddingRight:"0.3rem"}}>
استمع على
</h6>
<h6 style={{fontSize:"0.8rem",paddingRight:"0.1rem",color:"#FF4401",fontWeight:"bold"}}>
    SOUNDCLOUD
  </h6>
</div>

</div>

</div>


  </div>
  </div>
  </div> */}
        </main>
      </body>

      <Footer />
    </div>
  )
}
export default Podcast
