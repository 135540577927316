import React, { Component } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import HeroImg from "./HeroImg"
const Objectives_2030 = () => {
  return (
    <div>
      <Helmet>
        <title>أهدافنا و رؤية ٢٠٣٠م</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="أهدافنا و رؤية ٢٠٣٠م" />
        <main style={{ direction: "rtl" }}>
          <br />
          <br />
          <div className="col-xs-12">
            <h2 className="section__title">توافق أهداف الجمعية مع موجهات التنمية المحلية</h2>
          </div>
          <div className="separator"></div>
          <div className="BigBox">
            <div className="ObjBox2">
              <h1 className="center" style={{ fontWeight: "bold" }}>
                الهدف الأول: تعزيز القيم الإسلامية والهوية الوطنية
              </h1>
            </div>
            <div className="ObjBox3">
              <p className="center">
                ١-١-١ <br />
                تعزيز القيم الإسلامية (الإتقان والانضباط، العزيمة والمثابرة)
                <br />
                <br />
                ١-٣-١ <br />
                غرس المبادئ والقيم الوطنية وتعزيز الانتماء الوطنية
                <br />
                <br />
                ١-٣-٣ <br />
                العناية باللغة العربية
                <br />
                <br />
              </p>
            </div>

            <div className="ObjBox2" style={{ marginTop: "30px" }}>
              <h1 className="center" style={{ fontWeight: "bold" }}>
                {" "}
                الهدف السادس: تمكين المسؤولية الاجتماعية
              </h1>
            </div>

            <div className="ObjBox3">
              <p className="center">
                ٦-١-٢ <br />
                تشجيع العمل التطوعي
                <br />
                <br />
                ٦-٣-٢ <br />
                تمكين المنظمات غير الربحية من تحقيق أثر أعمق (الحصول على التمويل، والمواهب والمعرفة وما إلى ذلك )
                <br />
                <br />
                <br />
                <br />
              </p>
            </div>
          </div>

          <div className="separator"></div>
          <div class="col center">
            <embed
              src="/./assets/Images/bulding_img.png"
              style={{ width: "650px", height: "500px" }}
              class="img-fluid"
              alt="..."
            />
          </div>
          <div className="col-xs-12">
            <h2 className="section__title">
              <br />
              برنامج جودة الحياة: فئة التراث والثقافة والفنون <br />
              مبادرة ٧-٣-٤: القراءة وجه الفكر والثقافة
            </h2>
          </div>
          <div className="separator"></div>

          <div className="BigBox2">
            <div className="ObjBox22">
              <h1 className="center" style={{ fontWeight: "bold" }}>
                {" "}
                الأثر{" "}
              </h1>
            </div>
            <div className="ObjBox33">
              <p className="center">
                <ul class="a right">
                  <li style={{ listStyleType: "circle" }}>
                    بناء استراتيجية للارتقاء بالمستوى الدولي للمملكة في مجالي الفنون والثقافة.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    برامج إلزامية في المدارس لمعالجة ظاهرتي ضعف القراءة والعزوف عنها.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    برامج نوعية داخل المدارس وخارجها للتمكين من استثمار المقروء في إنتاج المعرفة واستخدامها في الحياة.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    تأسيس عشر أكاديميات تؤهل للاحتراف الثقافي والفني.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    كوادر مؤهلة لتمثيل المملكة في المناسبات والمحافل الفنية والثقافية الإقليمية والدولية.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    إنشاء مكتبات صغيرة ثابتة ومتنقلة ومتاجر على الشبكة العنكبوتية لبيع الكتب واستعارتها.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    بناء شراكات مع المعاهد المتخصصة والجامعات الأهلية التي ترعى الثقافة والفنون الأدبية والإعلامية، لصقل
                    مهارات المتعلمين ذوي الميول والتوجهات الفنية والثقافية.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    اطلاق مسابقات نوعية تركز على استثمار المقروء في إنتاج المعرفة وإبراز القدرات والمواهب الفنية
                    والثقافية ورعايتها.
                    <br />
                  </li>
                </ul>
                <br />
              </p>
            </div>

            <div className="separator"></div>
            <div className="ObjBox22">
              <h1 className="center" style={{ fontWeight: "bold" }}>
                {" "}
                فئة المشاركة المجتمعية{" "}
              </h1>
            </div>
            <div className="ObjBox33">
              <p className="center">
                <ul class="a right">
                  <li style={{ listStyleType: "circle" }}>
                    مبادرة ١-٣-١٠: بناء ثقافة ودافع للتطوع.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    مبادرة ٣-٣-١٠: تمكين وتنظيم العمل التطوعي في القطاع غير الربحي والحكومة.
                    <br />
                    <br />
                  </li>
                  <li style={{ listStyleType: "circle" }}>
                    مبادرة ٦-٣-١٠: تنظيم وتمكين المشاركة المجتمعية.
                    <br />
                  </li>
                </ul>
                <br />
              </p>
            </div>
            <div className="separator"></div>
          </div>
        </main>
        <Footer />
      </body>
    </div>
  )
}
export default Objectives_2030
