import React from 'react';

function HeroImg({ title, title2}) {
    return (
       
        <div class="img-holder">
         <img src="/assets/Images/Group112.svg" style={{ width: '100%', height: '100%' }} alt="Background" />
         <p className='med-512'>{title}</p>
       
     
      {title2 && (
        <React.Fragment>
          <p style={{top:"60%",right:"12%",fontWeight:"normal"}} className='xsmall2'>{title2}</p>
        </React.Fragment>
      )}
      </div>
    );
}

export default HeroImg;
