import React, { useState, useEffect } from "react"
import Header from "./Header2"
import Footer from "./Footer"
import { Helmet } from "react-helmet"

import DataEdu from "./DataEdu"
import HeroImg from "./HeroImg"

const ProgEdu = () => {
  const defaultCategory = "رواح" // Default category
  const [selectedCategory, setSelectedCategory] = useState(defaultCategory)
  const [filteredData, setFilteredData] = useState([])

  useEffect(() => {
    // Filter the data based on the default category when the component mounts
    const defaultCategoryData = DataEdu.find(item => item.category === defaultCategory)

    if (defaultCategoryData) {
      setFilteredData(defaultCategoryData.list)
    }
  }, [defaultCategory])

  const handleButtonClick = category => {
    // Filter the data based on the selected category
    const filteredCategory = DataEdu.find(item => item.category === category)

    if (filteredCategory) {
      setFilteredData(filteredCategory.list)
      setSelectedCategory(category)
    }
  }

  return (
    <div>
      <Helmet>
        <title> برامج نشر ثقافة القراءة</title>
      </Helmet>
      <Header />
      <body>
        <HeroImg title="برامج نشر ثقافة القراءة" />
        <main>
          <div className="d-flex justify-content-center my-4 flex-wrap">
            {DataEdu.map(item => (
              <button
                key={item.id}
                onClick={() => handleButtonClick(item.category)}
                className={
                  selectedCategory === item.category
                    ? " mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2  active-button"
                    : "btn-outline btn btn-outline-success mx-1 my-3 my-sm-0 pr-5 pl-5 mr-2 ml-2"
                }
              >
                <span class="small"> {item.category}</span>
              </button>
            ))}
          </div>

          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div className="news" style={{ width: "1000px" }}>
              {filteredData.map(item => {
                return (
                  <div className="card" style={{ backgroundColor: "rgba(0,0,0,.03)", width: "300px" }}>
                    <a href={item.link}>
                      <div
                        className="card-body gallery-img"
                        style={{ backgroundImage: `url("https://i.imgur.com/6rXnNXG.png")`, width: "300px" }}
                      ></div>
                      <div className="card-footer text-left">
                        <p className="card-text title-card xsmall text-right" style={{ minHeight: "2lh" }}>
                          {item.title}
                        </p>
                        <p className="card-text s16 detail-card xsmall">
                          عرض<i class="fa fa-long-arrow-left" aria-hidden="true"></i>
                        </p>
                      </div>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </main>
        <br />
        <br />
        <Footer />
      </body>
    </div>
  )
}
export default ProgEdu
