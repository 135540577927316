export const CardG = ({ item }) => {
  return (
    <>
      <div>
        <div className="container ">
          <div className="row flex-wrap justify-content-center">
            {item.map(Val => {
              return (
                <div className="col-md-4 col-sm-6 col-xs-8 card m-2 p-0" key={Val.id}>
                  <a
                    href="#"
                    onClick={() => {
                      window.location.href = `${Val.link}${Val.id}`
                    }}
                  >
                    <div className="card-body gallery-img" style={{ backgroundImage: `url("${Val.img}")` }}>
                      {/* <a href="">
              <span class="FolderIcon">
                  <img src="https://i.imgur.com/1shtZPT.jpg" title="" width={100} height={100}/>
                  <i class="fa fa-folder-open-o fa-5x mb-2"></i>
              </span>

          </a> */}

                      {/* <div class="container22">
                      <div class="box22">
                        <img src="https://i.imgur.com/4ppptCl.png" title="" width={170} height={170} />
                      </div>
                      <div class="box22 overlay22">
                        <img src={Val.img} title="" width={130} height={100} />
                      </div>
                    </div> */}
                    </div>
                    <div className="card-footer text-left">
                      <p className="card-text title-card small">{Val.category}</p>
                      <p className="card-text s16 detail-card xsmall2" style={{textAlign:"right"}}>
                        {Val.title}
                        <i class="fa fa-long-arrow-left mx-2" style={{ color: "#79121f" }} aria-hidden="true"></i>
                      </p>
                    </div>
                  </a>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
